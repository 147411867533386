import { useEffect, useState } from 'react';

import { replaceQueryParameters } from 'shared/replace-query-parameters';
import { fromQueryString } from 'shared/from-query-string';
import useCustomEvent from 'hooks/use-custom-event';
import window from 'ts/window';

type QueryOrFunction<T> = T | ((state: T) => T);
type StateOutput = ReturnType<typeof fromQueryString>;
type StateInput = Exclude<
  Parameters<typeof replaceQueryParameters>[1],
  undefined
>;

/** The `shouldListenForGlobal` argument can be used to force the state to be updated whenever the URL is changed by another instance of `useUrlState` */
function useUrlState<T extends StateInput>(
  shouldListenForGlobalChange?: boolean
): [
  StateOutput,
  (queryOrFunction: QueryOrFunction<Partial<T>>) => void,
  boolean
];
function useUrlState(shouldListenForGlobalChange?: boolean) {
  const [state, setState] = useState({});
  const [hasReadUrl, setHasReadUrl] = useState(false);

  const [dispatch] = useCustomEvent<undefined>('url-update', () => {
    if (shouldListenForGlobalChange && window.location) {
      setState(fromQueryString(window.location?.search));
    }
  });

  useEffect(() => {
    if (!window.location) return;
    setState(fromQueryString(window.location?.search));
    setHasReadUrl(true);
  }, []);

  const setUrlState: ReturnType<typeof useUrlState>['1'] = queryOrFunction => {
    if (!window.location) return;
    const query =
      typeof queryOrFunction === 'function'
        ? queryOrFunction(state)
        : queryOrFunction;
    const newUrl = replaceQueryParameters(window.location?.href, query);
    history.replaceState(null, document.title, newUrl);
    setState(fromQueryString(window.location?.search));
    dispatch(undefined);
  };

  return [state, setUrlState, hasReadUrl];
}

export default useUrlState;
