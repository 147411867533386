import AutomaticLogoutPage_import from '/home/vsts/work/1/s/client/pages/automatic-logout-page/automatic-logout-page.tsx';
import ClubsPage_import from '/home/vsts/work/1/s/client/pages/clubs-page/clubs-page.tsx';
import ConfirmationPage_import from '/home/vsts/work/1/s/client/pages/confirmation-page/confirmation-page.tsx';
import DropInConfirmationPage_import from '/home/vsts/work/1/s/client/pages/drop-in-confirmation-page/drop-in-confirmation-page.tsx';
import ErrorPage_import from '/home/vsts/work/1/s/client/pages/error-page/error-page.tsx';
import FriendConfirmationPage_import from '/home/vsts/work/1/s/client/pages/friend-confirmation-page/friend-confirmation-page.tsx';
import HelperPage_import from '/home/vsts/work/1/s/client/pages/helper-page/helper-page.tsx';
import LeadPage_import from '/home/vsts/work/1/s/client/pages/lead-page/lead-page.tsx';
import LoginPage_import from '/home/vsts/work/1/s/client/pages/login-page/login-page.tsx';
import ParkedPage_import from '/home/vsts/work/1/s/client/pages/parked-page/parked-page.tsx';
import PayPage_import from '/home/vsts/work/1/s/client/pages/pay-page/pay-page.tsx';
import RegistrationPage_import from '/home/vsts/work/1/s/client/pages/registration-page/registration-page.tsx';
import RootPage_import from '/home/vsts/work/1/s/client/pages/root-page/root-page.tsx';
import SelectAccessLevelPage_import from '/home/vsts/work/1/s/client/pages/select-access-level-page/select-access-level-page.tsx';
import SelectClubPage_import from '/home/vsts/work/1/s/client/pages/select-club-page/select-club-page.tsx';
import SelectMemberTypePage_import from '/home/vsts/work/1/s/client/pages/select-member-type-page/select-member-type-page.tsx';
import SelectMembershipPage_import from '/home/vsts/work/1/s/client/pages/select-membership-page/select-membership-page.tsx';
import SimpleConfirmationPage_import from '/home/vsts/work/1/s/client/pages/simple-confirmation-page/simple-confirmation-page.tsx';
import WebAdmin_import from '/home/vsts/work/1/s/client/pages/web-admin/web-admin.tsx';
import WebAdminData_import from '/home/vsts/work/1/s/client/pages/web-admin-data/web-admin-data.tsx';
import WebAdminLogin_import from '/home/vsts/work/1/s/client/pages/web-admin-login/web-admin-login.tsx';
import WebAdminSettings_import from '/home/vsts/work/1/s/client/pages/web-admin-settings/web-admin-settings.tsx';
import WebAdminSitemap_import from '/home/vsts/work/1/s/client/pages/web-admin-sitemap/web-admin-sitemap.tsx';
import Html_import from '../../client/html.tsx';
export default {
AutomaticLogoutPage: AutomaticLogoutPage_import,
ClubsPage: ClubsPage_import,
ConfirmationPage: ConfirmationPage_import,
DropInConfirmationPage: DropInConfirmationPage_import,
ErrorPage: ErrorPage_import,
FriendConfirmationPage: FriendConfirmationPage_import,
HelperPage: HelperPage_import,
LeadPage: LeadPage_import,
LoginPage: LoginPage_import,
ParkedPage: ParkedPage_import,
PayPage: PayPage_import,
RegistrationPage: RegistrationPage_import,
RootPage: RootPage_import,
SelectAccessLevelPage: SelectAccessLevelPage_import,
SelectClubPage: SelectClubPage_import,
SelectMemberTypePage: SelectMemberTypePage_import,
SelectMembershipPage: SelectMembershipPage_import,
SimpleConfirmationPage: SimpleConfirmationPage_import,
WebAdmin: WebAdmin_import,
WebAdminData: WebAdminData_import,
WebAdminLogin: WebAdminLogin_import,
WebAdminSettings: WebAdminSettings_import,
WebAdminSitemap: WebAdminSitemap_import,
Html: Html_import
};