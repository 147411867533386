import React from 'react';

import { ThemedComponent } from 'client/types';

import { mod } from 'ts/add-bem-modifiers';
import { replaceQueryParameters } from 'shared/replace-query-parameters';

import { Link as Props, themes } from './link.types';

const Link: ThemedComponent<Props, typeof themes> = ({
  children,
  className,
  href,
  text,
  theme,
  query,
  ...rest
}) => (
  <a
    className={[mod('link', theme), className].join(' ')}
    href={replaceQueryParameters(href, query)}
    {...rest}
  >
    {text ? text : children ? children : null}
  </a>
);

Link.themes = themes;

export default Link;
