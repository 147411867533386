import React from 'react';

import Flag from 'sats-ui-lib/react/flag';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import List from 'components/list/list';
import Price from 'components/price/price';

import { AccessLevelCard as Props } from './access-level-card.types';

const AccessLevelCard: React.FunctionComponent<Props> = ({
  id,
  isRecommended,
  link,
  negativeBullets = [],
  positiveBullets = [],
  price,
  tag,
  title,
}) => {
  return (
    <div className="access-level-card">
      <Text
        elementName="h2"
        className="access-level-card__title"
        size={Text.sizes.headline3}
        theme={Text.themes.normal}
      >
        {title}
      </Text>

      {tag && (
        <div className="access-level-card__tag">
          <Flag text={tag} />
        </div>
      )}

      {price && (
        <div className="access-level-card__price">
          <Price
            color={Price.colors.orange}
            size={Price.sizes.medium}
            {...price}
          />
        </div>
      )}

      {(positiveBullets.length > 0 || negativeBullets.length > 0) && (
        <Text className="access-level-card__bullets" size={Text.sizes.small}>
          {positiveBullets.length > 0 && (
            <List theme={List.themes.greenCheckmarks}>{positiveBullets}</List>
          )}
          {negativeBullets.length > 0 && (
            <List theme={List.themes.redCrosses}>{negativeBullets}</List>
          )}
        </Text>
      )}

      {link && (
        <div className="access-level-card__link-wrapper">
          <LinkButton
            data-test-access-level={id}
            className="access-level-card__link"
            variant={
              isRecommended
                ? LinkButton.variants.cta
                : LinkButton.variants.secondary
            }
            {...link}
          />
        </div>
      )}
    </div>
  );
};

export default AccessLevelCard;
