import React, { useEffect, useState } from 'react';

import { replaceQueryParameters } from 'shared/replace-query-parameters';
import { fromQueryString } from 'shared/from-query-string';

import LinkButton from 'sats-ui-lib/react/link-button';
import Modal from 'sats-ui-lib/react/modal';
import Text from 'sats-ui-lib/react/text';

import { CheckoutSessionModal as Props } from './checkout-session-modal.types';

const CheckoutSessionModal: React.FunctionComponent<Props> = ({
  buttonText,
  modal,
  text,
  title,
}) => {
  const [href, setHref] = useState('');

  useEffect(() => {
    setHref(
      replaceQueryParameters(
        window.location.pathname,
        Object.assign(fromQueryString(window.location.search), {
          sessionId: undefined,
        })
      )
    );
  }, []);

  return (
    <Modal {...modal}>
      <div className="checkout-session-modal">
        <Text elementName="h2" theme={Text.themes.headline}>
          {title}
        </Text>
        <Text>{text}</Text>
        <LinkButton
          className="checkout-session-modal__button"
          variant={LinkButton.variants.secondary}
          href={href}
          text={buttonText}
        />
      </div>
    </Modal>
  );
};

export default CheckoutSessionModal;
