/* eslint-disable react/no-multi-comp */
import ChevronDown from '@sats-group/icons/24/arrow-down';
import ChevronUp from '@sats-group/icons/24/arrow-up';
import Collapse from 'react-tiny-collapse';
import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import Text from 'sats-ui-lib/react/text';

import WebAdminLayout from 'components/web-admin-layout/web-admin-layout';

import { WebAdminSettingsCodeTable as TableProps } from './web-admin-settings-code-table.types';
import { WebAdminSettingsSection as SectionProps } from './web-admin-settings-section.types';
import { WebAdminSettings as Props } from './web-admin-settings.types';

const WebAdminSettingsCodeTable: React.FunctionComponent<TableProps> = ({
  header,
  rows,
  title,
}) => (
  <table data-title={title} style={{ width: '100%' }}>
    <thead>
      <tr>
        {header.map(text => (
          <td key={text}>{text}</td>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows.map(texts => (
        <tr key={texts.join('')} style={{ verticalAlign: 'top' }}>
          {texts.map(text => (
            <td key={text}>
              <Text
                elementName="code"
                size={Text.sizes.small}
                style={{ wordBreak: 'break-word' }}
              >
                {text}
              </Text>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

const WebAdminSettingsSection: React.FunctionComponent<
  React.PropsWithChildren<SectionProps>
> = ({ children, title }) => {
  const [expanded, setExpanded] = useState(false);
  const toggle = () => setExpanded(expanded => !expanded);

  return (
    <div className="web-admin-settings__section">
      <div className="web-admin-settings__section-header">
        <Text elementName="h2" onClick={toggle}>
          {title}
        </Text>
        <Button
          size={Button.sizes.large}
          onClick={toggle}
          icon={expanded ? <ChevronUp /> : <ChevronDown />}
          text={
            expanded ? `Hide section "${title}"` : `Show section "${title}"`
          }
        />
      </div>
      <Collapse isOpen={expanded}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

const WebAdminSettings: React.FunctionComponent<Props> = ({ info, layout }) => (
  <WebAdminLayout {...layout}>
    <div className="web-admin-settings">
      {info.map(group => (
        <WebAdminSettingsSection key={group.title} title={group.title}>
          {group.description ? (
            <Text elementName="p">{group.description}</Text>
          ) : null}
          <WebAdminSettingsCodeTable {...group} />
        </WebAdminSettingsSection>
      ))}
    </div>
  </WebAdminLayout>
);

export default WebAdminSettings;
