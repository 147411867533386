import React, { useEffect, useRef, useState } from 'react';

import { queryNames } from 'shared/membership-initiation-helpers';
import apiHelper from 'ts/api-helper';
import extractServerError from 'ts/extract-server-error';
import serializeForm from 'ts/serialize-form';
import { publish } from 'ts/messages';
import { types } from 'ts/messages.types';
import { fillRegistrationForm } from 'ts/registration-form-filler';

import Button from 'sats-ui-lib/react/button';
import Checkbox from 'sats-ui-lib/react/checkbox';
import Modal from 'sats-ui-lib/react/modal';
import Text from 'sats-ui-lib/react/text';

import ClientOnlyForm from 'components/client-only-form/client-only-form';
import HiddenInput from 'components/hidden-input/hidden-input';
import Link from 'components/link/link';
import RichText from 'components/rich-text/rich-text';
import Spinner from 'components/spinner/spinner';

import { useCheckoutSessionContext } from 'contexts/checkout-session-context-provider';

import { RegistrationForm as Props } from './registration-form.types';
import RegistrationFormInputs from './registration-form-inputs';

const RegistrationForm: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({
  children,
  endpoint,
  genericError,
  header,
  hiddenInputs = [],
  isDebugMode,
  loggedInMember,
  logIn,
  logInExplanation,
  marketingConsent,
  memberFormInputs,
  notAcceptableError,
  onResponse = () => {},
  otherPayerCheckbox,
  payerFormInputs,
  submitLabel,
  termsAndConditions,
  termsError,
}) => {
  const { checkIsExpired } = useCheckoutSessionContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isMarketingConsentModalOpen, setMarketingConsentModalIsOpen] =
    useState(false);
  const [isTermsModalOpen, setTermsModalIsOpen] = useState(false);
  const [isOtherPayer, setIsOtherPayer] = useState(false);

  const messageOverride = (message: string) => () => {
    if (message) {
      publish({ text: message, theme: types.error });
      return true;
    }
  };

  const givenOrGenericMessageOverride = (responseBody: Record<string, any>) => {
    const serverError = extractServerError({ responseBody });
    return messageOverride(serverError ?? genericError)();
  };

  // NOTE: This component unmounts as soon as `onResponse` is called with a valid request,
  // so we make a reference to hold the response here so that we can use `.finally`.
  // `password` is only returned in test environments.
  const responseReference = useRef<{
    memberId: string;
    password?: string;
    [queryNames.doPayment]?: string;
  }>();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    responseReference.current = undefined;
    apiHelper
      .post(endpoint, serializeForm(e.currentTarget))
      .then(response => {
        responseReference.current = response;
      })
      .catch(checkIsExpired)
      .catch(
        apiHelper.overrideStatus({
          400: givenOrGenericMessageOverride,
          406: messageOverride(notAcceptableError),
          409: givenOrGenericMessageOverride,
          502: givenOrGenericMessageOverride,
          504: givenOrGenericMessageOverride,
        })
      )
      .catch(() => {
        publish({ text: genericError, theme: types.error });
      })
      .finally(() => {
        setIsLoading(false);

        if (responseReference.current) {
          onResponse(responseReference.current);
        }
      });
  };

  useEffect(() => {
    if (termsAndConditions === undefined) {
      publish({ text: termsError, theme: types.error });
    }
  }, [termsAndConditions]);

  return (
    <div className="registration-form">
      <Text elementName="h3" size={Text.sizes.headline3}>
        {header}
      </Text>
      {isDebugMode ? (
        <Button
          text="Populate registration field"
          onClick={() => fillRegistrationForm()}
        />
      ) : undefined}

      {loggedInMember ? (
        <div data-test-logged-in-other-payer>
          <Text>{loggedInMember.description}</Text>
          <form
            action={loggedInMember.logOut.endpoint}
            className="registration-form__logout"
            method="POST"
          >
            {loggedInMember.logOut.hiddenInputs.map(input => (
              <HiddenInput key={input.name} {...input} />
            ))}
            <Button
              data-test-log-out
              text={loggedInMember.logOut.text}
              type="submit"
              variant={Button.variants.secondary}
            />
          </form>
        </div>
      ) : null}

      <ClientOnlyForm
        className="registration-form__member-form"
        onSubmit={handleSubmit}
        method="POST"
      >
        {otherPayerCheckbox && payerFormInputs ? (
          <Checkbox
            {...otherPayerCheckbox}
            onChange={e => setIsOtherPayer(e.currentTarget.checked)}
          />
        ) : null}
        {isLoading && <Spinner theme={Spinner.themes.overlaySticky} />}
        {children}
        {hiddenInputs.map(input => (
          <HiddenInput key={input.name} {...input} />
        ))}

        {otherPayerCheckbox && payerFormInputs && isOtherPayer ? (
          <React.Fragment>
            {logIn ? (
              <React.Fragment>
                <Text>{logInExplanation}</Text>
                <Link {...logIn} data-test-other-payer-log-in />
              </React.Fragment>
            ) : null}
            <RegistrationFormInputs {...payerFormInputs} />
          </React.Fragment>
        ) : null}
        <RegistrationFormInputs
          {...memberFormInputs}
          title={
            isOtherPayer || loggedInMember ? memberFormInputs.title : undefined
          }
        />
        {termsAndConditions.terms ? (
          <div className="registration-form__terms">
            <Checkbox {...termsAndConditions.checkbox} required />
            <div>
              <Button
                {...termsAndConditions.button}
                variant={Button.variants.secondary}
                size={Button.sizes.small}
                onClick={() => setTermsModalIsOpen(true)}
              />
            </div>
            {isTermsModalOpen ? (
              <Modal
                {...termsAndConditions.modal}
                onClose={() => setTermsModalIsOpen(false)}
              >
                <RichText {...termsAndConditions.terms} />
              </Modal>
            ) : null}
          </div>
        ) : null}
        {marketingConsent && marketingConsent.terms ? (
          <div className="registration-form__terms">
            <Checkbox {...marketingConsent.checkbox} />
            <div>
              <Button
                {...marketingConsent.button}
                variant={Button.variants.secondary}
                size={Button.sizes.small}
                onClick={() => setMarketingConsentModalIsOpen(true)}
              />
            </div>
            {isMarketingConsentModalOpen ? (
              <Modal
                {...marketingConsent.modal}
                onClose={() => setMarketingConsentModalIsOpen(false)}
              >
                <RichText {...marketingConsent.terms} />
              </Modal>
            ) : null}
          </div>
        ) : null}

        <Button
          variant={Button.variants.cta}
          size={Button.sizes.large}
          type="submit"
          disabled={!termsAndConditions}
          text={submitLabel}
        />
      </ClientOnlyForm>
    </div>
  );
};

export default RegistrationForm;
