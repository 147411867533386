import React from 'react';

import ContentContainer from 'client/components/content-container/content-container';

import Text from 'sats-ui-lib/react/text';

import { RootPage as Props } from './root-page.types';

const RootPage: React.FunctionComponent<Props> = ({ links }) => (
  <main className="root-page">
    <ContentContainer theme={ContentContainer.themes.narrow}>
      <div className="root-page__content">
        <div className="root-page__title">
          <Text
            elementName="h1"
            size={Text.sizes.headline1}
            theme={Text.themes.headline}
            italic
          >
            Club Sales
          </Text>
        </div>
        <nav className="root-page__links">
          {links.map(({ href, text }) => (
            <div key={href}>
              <a data-test-market-link={href} href={href}>
                <Text
                  elementName="span"
                  size={Text.sizes.large}
                  theme={Text.themes.normal}
                >
                  {text}
                </Text>
              </a>
            </div>
          ))}
        </nav>
      </div>
    </ContentContainer>
  </main>
);

export default RootPage;
