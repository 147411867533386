import React from 'react';

import type { ThemedComponent } from 'client/types';

import { mod } from 'ts/add-bem-modifiers';
import { replaceQueryParameters } from 'shared/replace-query-parameters';

import Text from 'sats-ui-lib/react/text';

import type { CleanLink as Props } from './clean-link.types';
import { themes } from './clean-link.types';

const CleanLink: ThemedComponent<
  React.PropsWithChildren<Props>,
  typeof themes
> = ({
  children,
  className,
  elementName,
  href,
  text,
  theme,
  query,
  ...rest
}) => (
  <Text elementName={elementName}>
    <a
      className={[mod('clean-link', theme), className].join(' ')}
      href={replaceQueryParameters(href, query)}
      {...rest}
    >
      {text ? text : children ? children : null}
    </a>
  </Text>
);

CleanLink.themes = themes;

export default CleanLink;
