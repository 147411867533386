import React from 'react';

import Text from 'sats-ui-lib/react/text';

import CheckoutConfirmationSummary from 'components/checkout-confirmation-summary/checkout-confirmation-summary';
import CheckoutLayout from 'components/checkout-layout/checkout-layout';
import ContentContainer from 'components/content-container/content-container';
import ElementInterpolator from 'components/element-interpolator/element-interpolator';
import RichText from 'components/rich-text/rich-text';

import { ConfirmationPage as Props } from './confirmation-page.types';

const ConfirmationPage: React.FunctionComponent<Props> = ({
  email,
  error,
  layout,
  passwordTemplate,
  passwordTitle,
  personalDetails,
  membershipSummary,
  text,
}) => (
  <CheckoutLayout {...layout}>
    <div className="confirmation-page">
      {error ? (
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <div className="confirmation-page__error">{error}</div>
        </ContentContainer>
      ) : null}
      <ContentContainer theme={ContentContainer.themes.narrow}>
        {membershipSummary ? (
          <div className="confirmation-page__membership-summary">
            <CheckoutConfirmationSummary {...membershipSummary} />
          </div>
        ) : null}

        {personalDetails.map(details => (
          <div
            className="confirmation-page__personal-information"
            key={details.title}
          >
            <Text
              elementName="h2"
              size={Text.sizes.headline3}
              theme={Text.themes.normal}
            >
              {details.title}
            </Text>
            <dl className="confirmation-page__list">
              {details.fields.map(({ label, value }) => (
                <React.Fragment key={label}>
                  <dt>{label}</dt>
                  <dd>{value}</dd>
                </React.Fragment>
              ))}
            </dl>
          </div>
        ))}

        {passwordTemplate && email ? (
          <div className="confirmation-page__password">
            <Text elementName="h2" theme={Text.themes.normal}>
              {passwordTitle}
            </Text>
            <ElementInterpolator
              template={passwordTemplate}
              elements={{
                email: (
                  <Text elementName="span" theme={Text.themes.emphasis}>
                    {email}
                  </Text>
                ),
              }}
            />
          </div>
        ) : null}

        {text ? (
          <div className="confirmation-page__text">
            <RichText {...text} />
          </div>
        ) : null}
      </ContentContainer>
    </div>
  </CheckoutLayout>
);

export default ConfirmationPage;
