/** Remove elements that are `undefined` or `null`
```ts
[1, null, undefined, 2].filter(isDefined); // Returns [1, 2]
```
 */
export const isDefined = <T>(x: T): x is NonNullable<T> =>
  x !== undefined && x !== null;

/** Returns a comparator function to be used in `[].sort()`. Elements are compared by applying `func` to each element. Supports sorting by `boolean` values (in that case, `true` comes before `false` in the sorted list) */
export const by =
  <T>(func: (el: T) => any) =>
  (a: T, b: T) => {
    const A = func(a);
    const B = func(b);
    if (typeof A === 'boolean' && typeof B === 'boolean')
      return A && !B ? -1 : !A && B ? 1 : 0;
    else return A > B ? 1 : A < B ? -1 : 0;
  };

/** Returns a comparator function to be used in `[].sort()`. Elements are compared by their value at `key`. Supports sorting by `boolean` values (in that case, `true` comes before `false` in the sorted list) */
export const byProperty = <TObject extends object, TKey extends keyof TObject>(
  key: TKey
) => by<TObject>(object => object[key]);

/* Given a key-returning function, returns the elements grouped in an object according to the returned keys. A second argument must be passed to `reduce`. For javascript an empty object is enough. For typescript an object with properties or a type cast may be required.
```ts
[{ age: 10 }, { age: 80 }].reduce(
  groupBy(el => (el.age > 30 ? "old" : "young")),
  { old: [], young: [] }
); // Returns { old: [{ age: 80 }], young: [{ age: 10 }]}
```
 */
export const groupBy =
  <K extends string, V>(func: (el: V) => K | undefined) =>
  (acc: Record<K, V[]>, el: V): Record<K, V[]> => {
    const groupName = func(el);
    if (!groupName) return acc;
    if (!acc[groupName]) acc[groupName] = [];
    acc[groupName].push(el);
    return acc;
  };
