import React, { useState } from 'react';

import ContentContainer from 'client/components/content-container/content-container';
import HiddenInput from 'client/components/hidden-input/hidden-input';
import Link from 'client/components/link/link';
import Logo from 'client/components/logo/logo';
import PrefixInput from 'client/components/prefix-input/prefix-input';
import RadioList from 'client/components/radio-list/radio-list';
import RichText from 'client/components/rich-text/rich-text';

import Button from 'sats-ui-lib/react/button';
import Checkbox from 'sats-ui-lib/react/checkbox';
import Modal from 'sats-ui-lib/react/modal';
import TextArea from 'sats-ui-lib/react/text-area';
import TextInput from 'sats-ui-lib/react/text-input';
import VisuallyHidden from 'sats-ui-lib/react/visually-hidden';

import { LeadPage as Props } from './lead-page.types';

const LeadPage: React.FunctionComponent<Props> = ({
  form,
  logo,
  skip,
  title,
}) => {
  const [isTermsModalOpen, setTermsModalIsOpen] = useState(false);
  const [isMarketingConsentModalOpen, setMarketingConsentModalIsOpen] =
    useState(false);

  return (
    <div className="lead-page">
      <ContentContainer theme={ContentContainer.themes.veryNarrow}>
        <div className="lead-page__logo">
          <Logo {...logo} color={Logo.colors.dark} />
        </div>
        <VisuallyHidden>
          <h1>{title}</h1>
        </VisuallyHidden>
        <form action={form.endpoint} className="lead-page__form" method="POST">
          {form.hiddenInputs.map(input => (
            <HiddenInput key={input.name} {...input} />
          ))}
          <TextInput {...form.firstName} hasMovingLabel required />
          <TextInput {...form.lastName} hasMovingLabel required />
          <PrefixInput {...form.phoneNumber} hasMovingLabel required />
          {form.over18 ? <Checkbox {...form.over18} /> : null}
          <RadioList {...form.personType} />
          <TextArea {...form.comment} />
          {form.termsAndConditions ? (
            <div className="lead-page__terms">
              <Checkbox {...form.termsAndConditions.checkbox} required />
              <div>
                <Button
                  {...form.termsAndConditions.button}
                  variant={Button.variants.secondary}
                  size={Button.sizes.small}
                  onClick={() => setTermsModalIsOpen(true)}
                />
              </div>
              {isTermsModalOpen ? (
                <Modal
                  {...form.termsAndConditions.modal}
                  onClose={() => setTermsModalIsOpen(false)}
                >
                  <RichText {...form.termsAndConditions.terms} />
                </Modal>
              ) : null}
            </div>
          ) : null}
          {form.marketingConsent && form.marketingConsent.terms ? (
            <div className="lead-page__terms">
              <Checkbox {...form.marketingConsent.checkbox} />
              <div>
                <Button
                  {...form.marketingConsent.button}
                  variant={Button.variants.secondary}
                  size={Button.sizes.small}
                  onClick={() => setMarketingConsentModalIsOpen(true)}
                />
              </div>
              {isMarketingConsentModalOpen ? (
                <Modal
                  {...form.marketingConsent.modal}
                  onClose={() => setMarketingConsentModalIsOpen(false)}
                >
                  <RichText {...form.marketingConsent.terms} />
                </Modal>
              ) : null}
            </div>
          ) : null}
          <div>
            <Button text={form.submit} type="submit" wide />
          </div>
          <div className="lead-page__skip">
            <Link data-test-skip-lead {...skip} />
          </div>
        </form>
      </ContentContainer>
    </div>
  );
};

export default LeadPage;
