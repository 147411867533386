/* eslint-disable react/no-multi-comp */
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import React from 'react';

import Text from 'sats-ui-lib/react/text';

import AssetHyperlink from 'components/rich-text/asset-hyperlink';
import ContentContainer from 'components/content-container/content-container';
import DynamicImage from 'components/dynamic-image/dynamic-image';
import EmbeddedAssetRenderer from 'components/rich-text/embedded-asset-renderer';
import TableRenderer from 'components/rich-text/table-renderer';

export const renderOptions = {
  default: {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: AssetHyperlink,
      [BLOCKS.EMBEDDED_ASSET]: EmbeddedAssetRenderer,
      [BLOCKS.TABLE]: TableRenderer,
    },
  },
  inspirationalArticle: {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: AssetHyperlink,
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const image = node?.data?.target?.fields?.file;
        if (!image?.url) return null;

        const dynamicImage = {
          alt: image.title,
          src: image.url,
        };

        return (
          <ContentContainer theme={ContentContainer.themes.narrow}>
            <DynamicImage
              className="inspirational-article-page__img"
              {...dynamicImage}
            />
          </ContentContainer>
        );
      },

      [BLOCKS.PARAGRAPH]: (node: any, children: any) => (
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <p>{children}</p>
        </ContentContainer>
      ),
      [BLOCKS.HEADING_1]: (node: any, children: any) => (
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <Text elementName="h1">{children}</Text>
        </ContentContainer>
      ),
      [BLOCKS.HEADING_2]: (node: any, children: any) => (
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <Text elementName="h2">{children}</Text>
        </ContentContainer>
      ),
      [BLOCKS.HEADING_3]: (node: any, children: any) => (
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <Text elementName="h3">{children}</Text>
        </ContentContainer>
      ),
      [BLOCKS.HEADING_4]: (node: any, children: any) => (
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <Text elementName="h4">{children}</Text>
        </ContentContainer>
      ),
      [BLOCKS.HEADING_5]: (node: any, children: any) => (
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <Text elementName="h5">{children}</Text>
        </ContentContainer>
      ),
      [BLOCKS.HEADING_6]: (node: any, children: any) => (
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <Text elementName="h6">{children}</Text>
        </ContentContainer>
      ),
      [BLOCKS.UL_LIST]: (node: any, children: any) => (
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <ul>
            {children.map((child: any) => (
              <li>{child}</li>
            ))}
          </ul>
        </ContentContainer>
      ),
      [BLOCKS.OL_LIST]: (node: any, children: any) => (
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <ol>
            {children.map((child: any) => (
              <li>{child}</li>
            ))}
          </ol>
        </ContentContainer>
      ),
      [BLOCKS.TABLE]: (node: any, children: any) => (
        <ContentContainer theme={ContentContainer.themes.narrow}>
          {TableRenderer(node, children)}
        </ContentContainer>
      ),
    },
  },
};
