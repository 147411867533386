import { fromQueryString } from 'shared/from-query-string';
import { isString } from 'shared/is';

import extractServerError from './extract-server-error';
import { trackEvents } from './tracking';
import { publish } from './messages';
import { isMessageType } from './messages.types';

const defaultFetchOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const parseResponse = (response: Response) =>
  response
    .text()
    .then(text => (text ? JSON.parse(text) : {}))
    .catch(() => {})
    .then(json => ({ json: json || {}, response }));

class ApiError extends Error {
  responseStatus: number;
  responseBody: any;
  extra: {
    query: any;
    response: any;
  };
  constructor(message: string) {
    super(message);
  }
}

const handleMessages = ({
  json,
  response,
}: {
  json: any;
  response: Response;
}) => {
  if (Array.isArray(json.messages)) {
    json.messages.forEach((message: any) => {
      if (!message) return;

      const userMessage = message.message;
      const userMessageType = message.type;

      if (isString(userMessage)) {
        publish({
          text: userMessage,
          theme: isMessageType(userMessageType) ? userMessageType : undefined,
        });
      }
    });
  }

  return { json, response };
};

const handleNotOk = ({ json, response }: { json: any; response: Response }) => {
  // NOTE: `response.ok` is true when the returned status is in the inclusive range 200-299.
  if (!response.ok) {
    const message = extractServerError({ responseBody: json });
    const error = new ApiError(message || response.statusText);

    const [url, query] = response.url.split('?');

    error.name = `${response.status} on ${url}`;
    error.responseStatus = response.status;
    error.responseBody = json;
    error.extra = {
      query: query ? fromQueryString(query) : undefined,
      response: json,
    };

    throw error;
  }

  return json;
};

/** Use this in a `catch` before a generic `catch`.

If a specified status' handler returns a truthy value,
the rejection is not propagated further.
If a specified status' handler returns a falsy value,
the rejection is propagated, so the callee can handle any
generic errors by cleaning up UI, displaying a message,
cancelling timers etc.

```js
post("some/url")
      .then(resultHandler)
      .catch(overrideStatus({
        401: () => {
          showErrorMessage("Please log in");
          return true;
        }
      }))
      .catch(genericErrorHandler)
      .finally(finallyHandler)
```
*/
const overrideStatus =
  (overrides: Record<number, (body: any) => boolean | undefined> = {}) =>
  (error: ApiError) => {
    if (overrides[error.responseStatus]?.(error.responseBody || {})) {
      return;
    }

    throw error;
  };

const handleAnalytics = (data: any) => {
  if (data?.dataLayer) {
    trackEvents(data.dataLayer);
  }
  return data;
};

const unpackPayload = (body: any) => (body ? body.payload || body : body);

const request = <T = any>(
  url: string,
  options: RequestInit = {}
): Promise<T> => {
  const fetchOptions = {
    ...defaultFetchOptions,
    ...options,
    headers: {
      ...defaultFetchOptions.headers,
      ...options.headers,
    },
  };
  return fetch(url, fetchOptions)
    .then(parseResponse)
    .then(handleMessages)
    .then(handleNotOk)
    .then(handleAnalytics)
    .then(unpackPayload);
};

// NOTE: 'delete' is a reserved word
const Delete = <T = any>(endpoint: string, options: RequestInit = {}) =>
  request<T>(endpoint, { ...options, method: 'delete' });

const get = <T = any>(endpoint: string, options: RequestInit = {}) =>
  request<T>(endpoint, options);

const post = <T = any>(endpoint: string, data?: any, options?: RequestInit) =>
  request<T>(
    endpoint,
    Object.assign({}, options, {
      body: JSON.stringify(data),
      method: 'post',
    })
  );

export default {
  defaultFetchOptions,
  delete: Delete,
  get,
  overrideStatus,
  post,
};
