import React from 'react';

import ContentContainer from 'client/components/content-container/content-container';
import Link from 'client/components/link/link';
import Logo from 'client/components/logo/logo';

import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import { SimpleConfirmationPage as Props } from './simple-confirmation-page.types';

const SimpleConfirmationPage: React.FunctionComponent<Props> = ({
  body,
  button,
  link,
  logo,
  title,
}) => (
  <main className="simple-confirmation-page">
    <ContentContainer theme={ContentContainer.themes.narrow}>
      <div className="simple-confirmation-page__content">
        <div className="simple-confirmation-page__logo">
          <Logo {...logo} color={Logo.colors.dark} />
        </div>
        <Text
          elementName="h1"
          size={Text.sizes.headline1}
          theme={Text.themes.headline}
          italic
        >
          {title}
        </Text>
        <Text
          elementName="p"
          size={Text.sizes.basic}
          theme={Text.themes.normal}
        >
          {body}
        </Text>
        <div>
          <LinkButton {...button} />
        </div>
        <div>
          <Link {...link} />
        </div>
      </div>
    </ContentContainer>
  </main>
);

export default SimpleConfirmationPage;
