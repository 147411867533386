import imageQuery from 'ts/image-query';
import useBoundingClientRect from 'hooks/use-bounding-client-rect';
import useDevicePixelRatio from 'hooks/use-device-pixel-ratio';
import { tuple } from 'shared/tuple';

export default function useImageQuery(defaultWidth = 100, defaultHeight = 100) {
  const dpr = useDevicePixelRatio();
  const [setElement, rect] = useBoundingClientRect();
  const width = rect?.width || defaultWidth;
  const height = rect?.height || defaultHeight;

  return tuple([setElement, imageQuery(width * dpr, height * dpr)]);
}
