export default function debounce<T extends any[]>(
  callback: (...args: T) => void,
  wait: number
) {
  let timerRef: number;

  return (...args: T) => {
    clearTimeout(timerRef);
    timerRef = setTimeout(() => callback(...args), wait);
  };
}
