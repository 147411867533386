import React, { useEffect } from 'react';

import { replaceQueryParameters } from 'shared/replace-query-parameters';
import useUrlState from 'hooks/use-url-state';
import { publish } from 'ts/messages';

import Button from 'sats-ui-lib/react/button';
import Text from 'sats-ui-lib/react/text';

import CheckoutLayout from 'components/checkout-layout/checkout-layout';
import CheckoutSummaryContent from 'components/checkout-summary-content/checkout-summary-content';
import CheckoutSummaryWrapper from 'components/checkout-summary-wrapper/checkout-summary-wrapper';
import ContentContainer from 'components/content-container/content-container';
import HiddenInput from 'components/hidden-input/hidden-input';
import InfoBox from 'components/info-box/info-box';
import Payment from 'components/payment/payment';
import Testimonials from 'components/testimonials/testimonials';

import PaymentSummary from './payment-summary';
import { PayPage as Props } from './pay-page.types';

const PaymentPage: React.FunctionComponent<Props> = ({
  consentText,
  editorialCard,
  errorMessages,
  layout,
  payment,
  paymentProviderText,
  paymentSummary,
  paymentTitle,
  park,
  stalePageRedirectUrl,
  successRedirectUrl,
  summary,
  testimonials,
}) => {
  const [query] = useUrlState();
  const { stale } = query;

  useEffect(() => {
    if (stale) {
      window.location.replace(stalePageRedirectUrl);
    }
  }, [stale]);

  const onPaymentComplete = (transactionId: string) => {
    window.location.href = replaceQueryParameters(successRedirectUrl, {
      ...query,
      transactionId,
    });
  };

  useEffect(() => {
    errorMessages.forEach(text => publish({ text, theme: 'error' }));
  }, [errorMessages]);

  return (
    <CheckoutLayout {...layout}>
      <div className="pay-page">
        <ContentContainer>
          <div className="pay-page__content">
            {editorialCard && (
              <div className="pay-page__card">
                <InfoBox {...editorialCard} />
              </div>
            )}

            <div className="pay-page__payment">
              <PaymentSummary {...paymentSummary} />

              <div className="pay-page__payment-form">
                <Text
                  className="pay-page__payment-title"
                  elementName="h2"
                  theme={Text.themes.normal}
                  size={Text.sizes.headline3}
                >
                  {paymentTitle}
                </Text>
                <Payment {...payment} onPaymentComplete={onPaymentComplete} />

                <Text className="pay-page__consent" size={Text.sizes.small}>
                  {consentText}
                </Text>

                <Text className="pay-page__provider" size={Text.sizes.small}>
                  {paymentProviderText}
                </Text>

                <form action={park.endpoint} method="post">
                  {park.data.map(item => (
                    <HiddenInput key={item.name} {...item} />
                  ))}
                  <Button
                    text={park.label}
                    type="submit"
                    variant={Button.variants.secondary}
                    wide
                  />
                </form>
              </div>
            </div>

            <CheckoutSummaryWrapper>
              <CheckoutSummaryContent {...summary} />
            </CheckoutSummaryWrapper>

            {testimonials ? (
              <div className="pay-page__testimonials">
                <Testimonials {...testimonials} />
              </div>
            ) : null}
          </div>
        </ContentContainer>
      </div>
    </CheckoutLayout>
  );
};

export default PaymentPage;
