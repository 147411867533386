import React, { InputHTMLAttributes } from 'react';

import { ThemedComponent } from 'client/types';

import { mod } from 'ts/add-bem-modifiers';

import Radio from 'sats-ui-lib/react/radio';

import { RadioList as Props, themes } from './radio-list.types';

type RestProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'color' | 'value'>;

const RadioList: ThemedComponent<RestProps & Props, typeof themes> = ({
  defaultCheckedValue,
  label,
  options,
  showOnlyOneCustomError,
  theme,
  ...rest
}) => {
  return (
    <fieldset className={mod('radio-list', theme)}>
      <div>
        <legend>{label}</legend>
      </div>
      <div className="radio-list__radios">
        {options.map((option, index) => (
          <Radio
            defaultChecked={defaultCheckedValue === option.value}
            key={option.value}
            showCustomError={
              showOnlyOneCustomError && index + 1 < options.length
                ? false
                : true
            }
            {...option}
            {...rest}
          />
        ))}
      </div>
    </fieldset>
  );
};

RadioList.themes = themes;

export default RadioList;
