import { ObjectValues } from 'client/types';

import { Link } from 'components/link/link.types';
import { Logo } from 'components/logo/logo.types';

export const themes = {
  dark: 'dark',
  light: 'light',
} as const;

export type CheckoutLayoutHeader = {
  bullets?: string[];
  link?: Link;
  logo: Logo;
  theme?: ObjectValues<typeof themes>;
};
