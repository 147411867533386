import React from 'react';

import { ElementInterpolator as Props } from './element-interpolator.types';

// NOTE: Returns a list of strings and React elements
const replaceToken = (
  string: string,
  tokenName: string,
  element: Props['elements'][string]
) =>
  string
    .split(`$${tokenName}$`)
    .reduce(
      (accum, text, index) =>
        index === 0 ? [text] : [...accum, element, text],
      []
    );

const ElementInterpolator: React.FunctionComponent<Props> = ({
  elements,
  template,
}) => {
  if (!template) return null;

  const interpolatedElements = Object.entries(elements)
    .reduce(
      (accum, [tokenName, element]) =>
        accum.flatMap(item =>
          typeof item === 'string'
            ? replaceToken(item, tokenName, element)
            : item
        ),
      [template]
    )
    .map(child =>
      // NOTE: Remove any remaining tokens because in some places the data is not ready until after mount, in which case the raw tokens (like '$name$') would be visible for a second.
      typeof child === 'string' ? (
        <React.Fragment key={child}>
          {child.replace(/\$[a-zA-Z]+\$/g, '')}
        </React.Fragment>
      ) : (
        <React.Fragment key={child?.key}>{child}</React.Fragment>
      )
    );

  return <>{interpolatedElements}</>;
};

export default ElementInterpolator;
