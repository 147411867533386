// NOTE: The API uses very inconsistent naming for error responses
export default ({
  responseBody = {},
  message,
}: {
  responseBody?: Record<string, any>;
  message?: string;
}) =>
  responseBody.userMessage ||
  responseBody.exceptionMessage ||
  responseBody.systemMessage ||
  responseBody.errorMessage ||
  responseBody.message ||
  message;
