import React from 'react';

import elixiaAppleTouchIcon from 'assets/favicons/elixia/apple-touch-icon.png';
import elixiaFavicon16 from 'assets/favicons/elixia/favicon-16x16.png';
import elixiaFavicon32 from 'assets/favicons/elixia/favicon-32x32.png';
import elixiaFaviconIco from 'assets/favicons/elixia/favicon.ico';
import elixiaManifest from 'assets/favicons/elixia/site.webmanifest';
import elixiaMaskIcon from 'assets/favicons/elixia/safari-pinned-tab.svg';
import satsAppleTouchIcon from 'assets/favicons/sats/apple-touch-icon.png';
import satsFavicon16 from 'assets/favicons/sats/favicon-16x16.png';
import satsFavicon32 from 'assets/favicons/sats/favicon-32x32.png';
import satsFaviconIco from 'assets/favicons/sats/favicon.ico';
import satsManifest from 'assets/favicons/sats/site.webmanifest';
import satsMaskIcon from 'assets/favicons/sats/safari-pinned-tab.svg';

import { Favicons as Props } from './favicons.types';

const Favicons: React.FunctionComponent<Props> = ({ language }) =>
  language === 'fi' ? (
    <>
      <link
        href={elixiaAppleTouchIcon}
        rel="apple-touch-icon"
        sizes="180x180"
      />
      <link href={elixiaFavicon32} rel="icon" type="image/png" sizes="32x32" />
      <link href={elixiaFavicon16} rel="icon" type="image/png" sizes="16x16" />
      <link href={elixiaFaviconIco} rel="icon" type="image/x-icon" />
      <link href={elixiaManifest} rel="manifest" />
      <link color="#0d2134" href={elixiaMaskIcon} rel="mask-icon" />
      <meta content="#0d2134" name="msapplication-TileColor" />
      <meta content="#0d2134" name="theme-color" />
    </>
  ) : (
    <>
      <link href={satsAppleTouchIcon} rel="apple-touch-icon" sizes="180x180" />
      <link href={satsFavicon32} rel="icon" type="image/png" sizes="32x32" />
      <link href={satsFavicon16} rel="icon" type="image/png" sizes="16x16" />
      <link href={satsFaviconIco} rel="icon" type="image/x-icon" />
      <link href={satsManifest} rel="manifest" />
      <link color="#0d2134" href={satsMaskIcon} rel="mask-icon" />
      <meta content="#0d2134" name="msapplication-TileColor" />
      <meta content="#0d2134" name="theme-color" />
    </>
  );

export default Favicons;
