/* eslint-disable no-var */
var hasOwn = {}.hasOwnProperty;

// NOTE: This is copied from the source of 'classnames' and modified to produce BEM modifier classes. (source: https://github.com/JedWatson/classnames/blob/master/index.js)

/** Creates modifier classnames of the provided values. The API is similar to `classnames`. All values passed will be prefixed by `className` (the first argument)
 * 
 * Note that unlike with `classnames`, when an array is passed all elements must be strings

```
mod("block__element", "something", { "is-active": true });
// Returns "block__element block__element--something block__element--is-active"
```
 */
export function mod(...args: any[]) {
  var baseName = args[0];
  if (!baseName) return '';

  var classes = [baseName];

  for (var i = 1; i < args.length; i++) {
    var arg = args[i];
    if (!arg) continue;

    var argType = typeof arg;

    if (argType === 'string' || argType === 'number') {
      classes.push(baseName + '--' + arg);
    } else if (Array.isArray(arg)) {
      if (arg.length) {
        for (var el of arg) {
          if (el) {
            classes.push(baseName + '--' + el);
          }
        }
      }
    } else if (argType === 'object') {
      if (arg.toString !== Object.prototype.toString) {
        classes.push(baseName + '--' + arg.toString());
      } else {
        for (var key in arg) {
          if (hasOwn.call(arg, key) && arg[key]) {
            classes.push(baseName + '--' + key);
          }
        }
      }
    }
  }

  return classes.join(' ');
}
