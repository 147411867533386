import React from 'react';

import WebAdminLayout from 'client/components/web-admin-layout/web-admin-layout';

import Text from 'sats-ui-lib/react/text';

import { WebAdmin as Props } from './web-admin.types';

const WebAdmin: React.FunctionComponent<Props> = ({ layout, links }) => (
  <WebAdminLayout {...layout}>
    <div className="web-admin">
      <nav className="web-admin__links">
        {links.map(link => (
          <a className="web-admin__link" key={link.href} href={link.href}>
            <Text size={Text.sizes.basic} theme={Text.themes.normal}>
              {link.text}
            </Text>
          </a>
        ))}
      </nav>
    </div>
  </WebAdminLayout>
);

export default WebAdmin;
