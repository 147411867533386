import React from 'react';

import { HiddenInput as Props } from './hidden-input.types';

const HiddenInput: React.FunctionComponent<Props> = ({
  name,
  value,
  ...rest
}) => {
  return <input type="hidden" name={name} value={value} {...rest} />;
};

export default HiddenInput;
