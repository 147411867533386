import React from 'react';

import Link from 'components/link/link';
import { CheckoutProgressStepContent as Props } from 'components/checkout-progress/checkout-progress-step-content.types';

const CheckoutProgressStepContainer: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ children, className, isComplete, link, query }) =>
  isComplete && link ? (
    <Link {...link} className={className} query={query}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );

export default CheckoutProgressStepContainer;
