import { NodeRenderer } from '@contentful/rich-text-react-renderer';
import React from 'react';

import useImageQuery from 'hooks/use-image-query';

const EmbeddedAssetRenderer: NodeRenderer = node => {
  const [setElement, query] = useImageQuery();

  const image = node?.data?.target?.fields?.file;
  if (!image?.url) return null;

  const { height, width } = image.details.image;

  return (
    <div ref={setElement}>
      <img
        style={{ height: `${height}px`, width: `${width}px` }}
        alt={image.title || ''}
        src={`${image.url}${query}`}
      />
    </div>
  );
};

export default EmbeddedAssetRenderer;
