import React from 'react';

import useImageQuery from 'hooks/use-image-query';
import { mod } from 'ts/add-bem-modifiers';

import CroppedImage from 'sats-ui-lib/react/cropped-image';
import { aspectRatios } from 'sats-ui-lib/react/cropped-image/cropped-image.types';

import { DynamicImage as Props, themes } from './dynamic-image.types';

const DynamicImage: React.FunctionComponent<Props> & {
  themes: typeof themes;
  aspectRatios: typeof aspectRatios;
} = ({
  // NOTE: 150/85 roughly equals 16/9
  defaultHeight = 85,
  defaultWidth = 150,
  theme,
  ...props
}) => {
  const [setElement, query] = useImageQuery(defaultWidth, defaultHeight);

  return (
    <div className={mod('dynamic-image', theme)} ref={setElement}>
      <CroppedImage {...props} srcQuery={query} />
    </div>
  );
};

DynamicImage.aspectRatios = CroppedImage.aspectRatios;
DynamicImage.themes = themes;

export default DynamicImage;
