import { HiddenInput } from 'client/components/hidden-input/hidden-input.types';
import { ObjectValues } from 'client/types';

import { TextInput } from 'sats-ui-lib/react/text-input/text-input.types';
import { Message } from 'sats-ui-lib/react/message/message.types';

export const brands = {
  sats: 'sats',
  elixia: 'elixia',
} as const;

export type LoginPage = {
  brand: ObjectValues<typeof brands>;
  endpoint: string;
  hiddenInputs: HiddenInput[];
  inputs: TextInput[];
  message?: Message;
  submitLabel: string;
  text: string;
  title: string;
};
