import { ObjectValues } from 'client/types';

export const themes = {
  centered: 'centered',
  overlay: 'overlay',
  overlayBlue: 'overlay-blue',
  overlaySticky: 'overlay-sticky',
} as const;

export const sizes = {
  medium: 'medium',
  mini: 'mini',
};

export type Spinner = {
  size?: ObjectValues<typeof sizes>;
  theme?: ObjectValues<typeof themes>;
};
