import { ObjectValues } from 'client/types';

import { Radio } from 'sats-ui-lib/react/radio/radio.types';

export const themes = {
  horizontal: 'horizontal',
} as const;

export type RadioList = {
  defaultCheckedValue?: string;
  label: string;
  options: Radio[];
  showOnlyOneCustomError?: boolean;
  theme?: ObjectValues<typeof themes>;
};
