import React from 'react';

import Text from 'sats-ui-lib/react/text';

import List from 'components/list/list';

import { PaymentSummary as Props } from './pay-page.types';

const PaymentSummary: React.FunctionComponent<Props> = ({
  errorMessage,
  lines = [],
  title,
  text,
}) => (
  <div>
    {!lines.length && (
      <Text
        className="payment-page__payment-summary-error"
        size={Text.sizes.small}
      >
        {errorMessage}
      </Text>
    )}

    <Text
      className="payment-page__payment-summary-title"
      elementName="h2"
      theme={Text.themes.normal}
      size={Text.sizes.headline3}
    >
      {title}
    </Text>

    {lines.length > 0 && (
      <React.Fragment>
        {text && <Text size={Text.sizes.small}>{text}</Text>}

        <List
          className="payment-page__payment-summary-lines"
          theme={List.themes.marginMedium}
        >
          {lines.map(({ dates, isImportant, name, price }) => (
            <div className="payment-page__payment-summary-line" key={name}>
              <Text
                size={Text.sizes.small}
                theme={isImportant ? Text.themes.emphasis : Text.themes.normal}
              >
                {name}
                {dates && <div>{dates}</div>}
              </Text>
              <Text
                className="payment-page__payment-summary-price"
                size={Text.sizes.small}
                theme={Text.themes.emphasis}
              >
                {price}
              </Text>
            </div>
          ))}
        </List>
      </React.Fragment>
    )}
  </div>
);

export default PaymentSummary;
