import React from 'react';
import serialize from 'serialize-javascript';

import Favicons from 'components/favicons/favicons';

import { Html as Props } from './html.types';

const Html: React.FC<React.PropsWithChildren<Props>> = ({
  attributes = {},
  children,
  css = [],
  js = [],
  jsAsync = [],
  language,
  props,
  title,
}) => (
  <html lang={language} {...attributes}>
    <head>
      {css.map(file => (
        <link key={file} rel="stylesheet" href={file} />
      ))}
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="robots" content="none" />
      {jsAsync.map(file => (
        <script async key={file} src={file} />
      ))}
      <Favicons language={language} />
      <title>{title}</title>
    </head>
    <body>
      <div id="mount-point">{children}</div>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.__INITIAL_PROPS__ = ${serialize(props, {
            isJSON: true,
          })};`,
        }}
      />
      {js.map(file => (
        <script key={file} src={file} />
      ))}
    </body>
  </html>
);

export default Html;
