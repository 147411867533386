import ChevronDown from '@sats-group/icons/24/arrow-down';
import ChevronUp from '@sats-group/icons/24/arrow-up';
import Collapse from 'react-tiny-collapse';
import React, { useState } from 'react';

import { SitemapNode as Props } from './sitemap-node.types';

/* eslint-disable react/no-multi-comp */
const SitemapNode: React.FunctionComponent<Props> = ({
  data = {},
  parentUrl = '',
}) => {
  return (
    <ul style={{ paddingBottom: 20 }}>
      {Object.entries(data).map(([slug, children]) => {
        const url = [parentUrl, slug].join('/');

        const link =
          typeof children === 'object' && children['/'] ? (
            <a href={url}>{slug}</a>
          ) : typeof children === 'boolean' && slug !== '/' ? (
            <a href={url}>{slug}</a>
          ) : slug !== '/' ? (
            slug
          ) : null;

        const childNodes =
          typeof children === 'object' ? (
            <SitemapNode data={children} parentUrl={url} />
          ) : null;

        return link || childNodes ? (
          <ListElement header={link} key={slug}>
            {childNodes}
          </ListElement>
        ) : null;
      })}
    </ul>
  );
};

const buttonStyles: React.HTMLAttributes<HTMLButtonElement>['style'] = {
  appearance: 'none',
  marginLeft: 10,
  border: 'none',
  padding: 10,
  borderRadius: 20,
  backgroundColor: 'white',
};

const headerStyle = {
  display: 'inline-flex',
  justifyContent: 'space-between',
  minWidth: 80,
};

const ListElement: React.FunctionComponent<
  React.PropsWithChildren<{ header: React.ReactNode }>
> = ({ children, header }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return children ? (
    <li style={{ marginTop: 5 }}>
      <div style={headerStyle}>
        {header}
        <button onClick={() => setIsExpanded(a => !a)} style={buttonStyles}>
          {isExpanded ? <ChevronUp /> : <ChevronDown />}
        </button>
      </div>
      <Collapse isOpen={isExpanded}>{children}</Collapse>
    </li>
  ) : (
    <li>{header}</li>
  );
};

export default SitemapNode;
