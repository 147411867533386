import Check from '@sats-group/icons/24/check';
import React, { useEffect, useRef, useState } from 'react';

import { ThemedComponent } from 'client/types';

import { mod } from 'ts/add-bem-modifiers';

import Text from 'sats-ui-lib/react/text';

import CheckoutLogo from 'components/checkout-logo/checkout-logo';
import ContentContainer from 'components/content-container/content-container';
import Link from 'components/link/link';

import {
  CheckoutLayoutHeader as Props,
  themes,
} from './checkout-layout-header.types';

enum Position {
  left = 'left',
  middle = 'middle',
  right = 'right',
}

const CheckoutLayoutHeader: ThemedComponent<Props, typeof themes> = ({
  bullets = [],
  link,
  logo,
  theme = 'dark',
}) => {
  const bulletsRef = useRef<HTMLUListElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<Position | undefined>(undefined);

  const updatePosition = () => {
    if (!bulletsRef.current || !trackRef.current) {
      return;
    }

    const bulletsRect = bulletsRef.current.getBoundingClientRect();
    const trackRect = trackRef.current.getBoundingClientRect();

    if (trackRect.x - bulletsRect.x === 0) {
      setPosition(Position.left);
    } else if (Math.ceil(trackRect.right) === Math.ceil(bulletsRect.right)) {
      setPosition(Position.right);
    } else {
      setPosition(Position.middle);
    }
  };

  useEffect(updatePosition, []);

  return (
    <header className={mod('checkout-layout-header', theme)}>
      <ContentContainer className="checkout-layout-header__content">
        <div className="checkout-layout-header__logo">
          <CheckoutLogo
            {...logo}
            color={
              theme === themes.light
                ? CheckoutLogo.colors.dark
                : CheckoutLogo.colors.light
            }
          />
        </div>

        {bullets.length > 0 && (
          <div
            className={mod(
              'checkout-layout-header__track-container',
              theme,
              position
            )}
          >
            <div
              className="checkout-layout-header__track"
              onScroll={updatePosition}
              ref={trackRef}
            >
              <ul className="checkout-layout-header__bullets" ref={bulletsRef}>
                {bullets.map(text => (
                  <li className="checkout-layout-header__bullet" key={text}>
                    <Check />
                    <Text size={Text.sizes.small}>{text}</Text>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {link && (
          <Text
            className="checkout-layout-header__link-wrapper"
            size={Text.sizes.small}
          >
            <Link {...link} />
          </Text>
        )}
      </ContentContainer>
    </header>
  );
};

CheckoutLayoutHeader.themes = themes;

export default CheckoutLayoutHeader;
