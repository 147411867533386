import React from 'react';
import SvgArrowRight from '@sats-group/icons/24/arrow-right';

import LinkButton from 'sats-ui-lib/react/link-button';
import Message from 'sats-ui-lib/react/message';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';

import { WebAdminLayout as Props } from './web-admin-layout.types';

const WebAdminLayout: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ action, children, crumbs, messages }) => (
  <div className="web-admin-layout">
    <ContentContainer className="web-admin-layout__content">
      <div className="web-admin-layout__header">
        <div>
          <Text
            elementName="h1"
            size={Text.sizes.headline1}
            theme={Text.themes.headline}
            italic
          >
            SATS
          </Text>
        </div>
        <div>
          <LinkButton {...action} variant={LinkButton.variants.secondary} />
        </div>
      </div>
      {crumbs.length ? (
        <nav className="web-admin-layout__crumbs">
          {crumbs.map((crumb, index) => (
            <React.Fragment key={crumb.text}>
              {index === 0 ? null : (
                <div>
                  <SvgArrowRight />
                </div>
              )}
              <Text
                elementName={crumb.href ? 'a' : 'span'}
                href={crumb.href}
                size={Text.sizes.small}
                theme={Text.themes.normal}
              >
                {crumb.text}
              </Text>
            </React.Fragment>
          ))}
        </nav>
      ) : null}
      {messages.map(message => (
        <div key={message.text}>
          <Message {...message} inline />
        </div>
      ))}
      <main>{children}</main>
    </ContentContainer>
  </div>
);

export default WebAdminLayout;
