import React from 'react';

import Text from 'sats-ui-lib/react/text';

import { CheckoutConfirmationSummary as Props } from './checkout-confirmation-summary.types';

const CheckoutConfirmationSummary: React.FunctionComponent<Props> = ({
  list,
  title,
}) => (
  <div className="checkout-confirmation-summary">
    <Text
      elementName="h2"
      size={Text.sizes.headline3}
      theme={Text.themes.normal}
    >
      {title}
    </Text>
    <dl className="checkout-confirmation-summary__list">
      {list.map(({ label, value }) => (
        <React.Fragment key={label}>
          <dt>{label}</dt>
          <dd>
            <div className="checkout-confirmation-summary__membership-detail-value">
              {value}
            </div>
          </dd>
        </React.Fragment>
      ))}
    </dl>
  </div>
);

export default CheckoutConfirmationSummary;
