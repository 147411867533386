import Check from '@sats-group/icons/24/check';
import Close from '@sats-group/icons/24/close';
import React from 'react';

import { ThemedComponent } from 'client/types';

import { mod } from 'ts/add-bem-modifiers';

import { List as Props, themes } from './list.types';

const List: ThemedComponent<Props, typeof themes> = ({
  className,
  children,
  theme,
  ...rest
}) => {
  return (
    <ul className={[mod('list', theme), className].join(' ')} {...rest}>
      {React.Children.map(children, child => (
        <li className="list__item">
          {theme &&
            (themes.checkmarks === theme ||
              themes.greenCheckmarks === theme) && (
              <div className="list__checkmark">
                <Check />
              </div>
            )}
          {theme &&
            (themes.redCrosses === theme || themes.blackCrosses === theme) && (
              <div className="list__cross">
                <Close />
              </div>
            )}
          {child}
        </li>
      ))}
    </ul>
  );
};

List.themes = themes;

export default List;
