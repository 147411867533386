import ChevronUp from '@sats-group/icons/24/arrow-up';
import Collapse from 'react-tiny-collapse';
import React, { useState } from 'react';

import { mod } from 'ts/add-bem-modifiers';
import useToggle from 'hooks/use-toggle';

import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import CheckoutSummaryModal from 'components/checkout-summary-modal/checkout-summary-modal';
import Link from 'components/link/link';
import List from 'components/list/list';
import Price from 'components/price/price';
import Spinner from 'components/spinner/spinner';

import { CheckoutSummaryContent as Props } from './checkout-summary-content.types';

const subTitleProps = {
  className: 'checkout-summary__sub-title',
  elementName: 'h3',
  size: Text.sizes.small,
  theme: Text.themes.normal,
};

const CheckoutSummaryContent: React.FunctionComponent<Props> = ({
  bannerButtonText,
  bannerTitle,
  bannerText,
  bindingPrice,
  bindingPriceLabel,
  button,
  choiceGroups = [],
  isLoading,
  isMaximumPrice,
  maximumPriceModal,
  additionalInformation = [],
  price,
  priceTitle,
  title,
}) => {
  const [maximumPriceModalVisible, setMaximumPriceModalVisible] =
    useState(false);
  const [isOpen, toggle] = useToggle(false);

  const collapseProps = {
    className: 'checkout-summary-content__collapse',
    isOpen,
  };

  return (
    <div className="checkout-summary-content">
      {isLoading && <Spinner theme={Spinner.themes.overlaySticky} />}

      {maximumPriceModal && maximumPriceModalVisible && (
        <CheckoutSummaryModal
          hide={() => setMaximumPriceModalVisible(false)}
          {...maximumPriceModal}
        />
      )}

      <div className="checkout-summary-content__padded-content">
        <div className="checkout-summary-content__heading">
          <Text
            className="checkout-summary-content__title"
            elementName="h2"
            size={Text.sizes.headline3}
            theme={Text.themes.normal}
          >
            {title}
          </Text>
          <button
            className="checkout-summary-content__trigger"
            onClick={toggle}
          >
            <Text
              elementName="h2"
              size={Text.sizes.headline3}
              theme={Text.themes.normal}
            >
              {title}
            </Text>
            <div
              className={mod('checkout-summary-content__trigger-icon', {
                flipped: isOpen,
              })}
            >
              <ChevronUp />
            </div>
          </button>
        </div>

        <Collapse {...collapseProps}>
          <>
            {choiceGroups.length > 0 && (
              <div>
                {choiceGroups.map(({ link, texts, title }) => (
                  <div className="checkout-summary-content__choice" key={title}>
                    <Text {...subTitleProps}>{title}</Text>
                    <Text size={Text.sizes.small}>
                      {link && <Link theme={Link.themes.orange} {...link} />}
                    </Text>
                    <Text
                      className="checkout-summary-content__choice-list"
                      size={Text.sizes.small}
                    >
                      <List theme={List.themes.greenCheckmarks}>{texts}</List>
                    </Text>
                  </div>
                ))}
              </div>
            )}
            {additionalInformation.length
              ? additionalInformation.map(({ link, texts, title }) => (
                  <div className="checkout-summary-content__choice" key={title}>
                    <Text {...subTitleProps}>{title}</Text>
                    <Text size={Text.sizes.small}>
                      {link && <Link theme={Link.themes.orange} {...link} />}
                    </Text>
                    <Text
                      className="checkout-summary-content__choice-list"
                      size={Text.sizes.small}
                    >
                      <List>{texts}</List>
                    </Text>
                  </div>
                ))
              : null}
          </>
        </Collapse>
      </div>

      {bannerTitle && (
        <Collapse {...collapseProps}>
          <div className="checkout-summary-content__banner">
            <Text
              elementName="h3"
              size={Text.sizes.basic}
              theme={Text.themes.headline}
            >
              {bannerTitle}
            </Text>
            {bannerText && <Text size={Text.sizes.small}>{bannerText}</Text>}

            {maximumPriceModal && (
              <button
                className="checkout-summary-content__banner-button"
                onClick={() => setMaximumPriceModalVisible(true)}
              >
                <span
                  aria-hidden="true"
                  className="checkout-summary-content__banner-icon"
                >
                  ?
                </span>
                {bannerButtonText}
              </button>
            )}
          </div>
        </Collapse>
      )}

      {price && (
        <div
          className={mod('checkout-summary-content__price-wrapper', {
            green: isMaximumPrice,
          })}
        >
          <div className="checkout-summary-content__price">
            <Text {...subTitleProps}>{priceTitle}</Text>
            <div className="checkout-summary-content__price-inner">
              <Price
                color={
                  isMaximumPrice ? Price.colors.light : Price.colors.darkOrange
                }
                size={Price.sizes.medium}
                {...price}
              />
            </div>
          </div>
          {button && (
            <div className="checkout-summary-content__link-container">
              <LinkButton
                data-test-select-membership-next
                variant={LinkButton.variants.cta}
                size={LinkButton.sizes.large}
                {...button}
              />
            </div>
          )}
        </div>
      )}

      {bindingPrice && bindingPrice.price ? (
        <Collapse {...collapseProps}>
          <Text
            className="checkout-summary-content__binding-price"
            elementName="p"
            size={Text.sizes.small}
          >
            {bindingPriceLabel}
            <Text
              elementName="span"
              size={Text.sizes.small}
              theme={Text.themes.emphasis}
            >
              {' '}
              {bindingPrice.price}
              {bindingPrice.currency ? ` ${bindingPrice.currency}` : null}
            </Text>
          </Text>
        </Collapse>
      ) : null}
    </div>
  );
};

export default CheckoutSummaryContent;
