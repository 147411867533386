import React from 'react';

import LinkButton from 'sats-ui-lib/react/link-button';
import Message from 'sats-ui-lib/react/message';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';

import { WebAdminLogin as Props } from './web-admin-login.types';

const WebAdminLogin: React.FunctionComponent<Props> = ({
  error,
  link,
  title,
}) => (
  <div className="web-admin-login">
    <ContentContainer
      className="web-admin-login__content"
      theme={ContentContainer.themes.narrow}
    >
      <Text
        elementName="h1"
        size={Text.sizes.headline1}
        theme={Text.themes.emphasis}
      >
        {title}
      </Text>
      {error ? (
        <div>
          <Message inline text={error} theme={Message.themes.error} />
        </div>
      ) : null}
      <div>
        <LinkButton {...link} />
      </div>
    </ContentContainer>
  </div>
);

export default WebAdminLogin;
