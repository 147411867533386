import React from 'react';

import Flag from 'sats-ui-lib/react/flag';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import RichText from 'components/rich-text/rich-text';

import { CustomProductCard as Props } from './custom-product-card.types';

const CustomProductCard: React.FunctionComponent<Props> = ({
  link,
  tag,
  text,
  title,
}) => {
  return (
    <div className="custom-product-card">
      {tag && (
        <div className="custom-product-card__tag">
          <Flag text={tag} />
        </div>
      )}

      {title && (
        <Text
          elementName="h2"
          className="custom-product-card__title"
          size={Text.sizes.headline3}
          theme={Text.themes.normal}
        >
          {title}
        </Text>
      )}

      <Text className="custom-product-card__text" size={Text.sizes.small}>
        <RichText theme={RichText.themes.greenCheckmarkList} {...text} />
      </Text>

      {link && <LinkButton variant={LinkButton.variants.cta} {...link} />}
    </div>
  );
};

export default CustomProductCard;
