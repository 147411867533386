import { nanoid } from 'nanoid';

export const fillRegistrationForm = () => {
  const id = nanoid();

  const formData = [
    {
      name: 'payerFirstName',
      fieldName: 'value',
      value: `xxx--payer-first-${id}`,
    },
    {
      name: 'payerLastName',
      fieldName: 'value',
      value: `xxx--payer-last-${id}`,
    },
    {
      name: 'payerEmail',
      fieldName: 'value',
      value: `xxx-test-payer-${id}@example.com`,
    },
    {
      name: 'payerBirthDay',
      fieldName: 'value',
      value: `${Math.floor(Math.random() * 28) + 1}`,
    },
    {
      name: 'payerBirthMonth',
      fieldName: 'value',
      value: `${Math.floor(Math.random() * 12) + 1}`,
    },
    {
      name: 'payerBirthYear',
      fieldName: 'value',
      value: `${
        new Date().getFullYear() - Math.floor(Math.random() * 70) - 18
      }`,
    },
    {
      name: 'payerGender',
      fieldName: 'checked',
      value: true,
    },
    {
      name: 'payerAddress',
      fieldName: 'value',
      value: `xxx-${id}`,
    },
    {
      name: 'payerPostalCode',
      fieldName: 'value',
      value: `0558`,
    },
    {
      name: 'payerPhoneNumber',
      fieldName: 'value',
      value: `${
        Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000
      }`,
    },

    {
      name: 'firstName',
      fieldName: 'value',
      value: `xxx-first-${id}`,
    },
    {
      name: 'lastName',
      fieldName: 'value',
      value: `xxx-last-${id}`,
    },
    {
      name: 'email',
      fieldName: 'value',
      value: `xxx-test-${id}@example.com`,
    },
    {
      name: 'birthDay',
      fieldName: 'value',
      value: `${Math.floor(Math.random() * 28) + 1}`,
    },
    {
      name: 'birthMonth',
      fieldName: 'value',
      value: `${Math.floor(Math.random() * 12) + 1}`,
    },
    {
      name: 'birthYear',
      fieldName: 'value',
      value: `${
        new Date().getFullYear() - Math.floor(Math.random() * 70) - 18
      }`,
    },
    {
      name: 'gender',
      fieldName: 'checked',
      value: true,
    },
    {
      name: 'address',
      fieldName: 'value',
      value: `xxx-${id}`,
    },
    {
      name: 'postalCode',
      fieldName: 'value',
      value: `0558`,
    },
    {
      name: 'phoneNumber',
      fieldName: 'value',
      value: `${
        Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000
      }`,
    },
    {
      name: 'termsAndConditions',
      fieldName: 'checked',
      value: true,
    },
  ];

  formData.forEach(({ name, fieldName, value }) => {
    const element = document.querySelector(`[name=${name}]`) as Record<
      string,
      any
    >;
    if (element) {
      element[fieldName] = value;
    }
    return;
  });
};
