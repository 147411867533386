import { useState } from 'react';

const useToggle = (
  initialState: boolean
): [
  state: boolean,
  toggle: () => void,
  turnOn: () => void,
  turnOff: () => void
] => {
  const [state, setState] = useState(!!initialState);
  const toggle = () => setState(!state);
  const turnOn = () => setState(true);
  const turnOff = () => setState(false);

  return [state, toggle, turnOn, turnOff];
};

export default useToggle;
