import cn from 'classnames';
import React from 'react';

import Elixia from 'sats-ui-lib/react/logos/elixia-small';
import Sats from 'sats-ui-lib/react/logos/sats-small';

import Link from 'components/link/link';

import { Logo as Props, brands, colors } from './logo.types';

const Logo: React.FunctionComponent<React.PropsWithChildren<Props>> & {
  colors: typeof colors;
  brands: typeof brands;
} = ({ brand = brands.sats, color = colors.light, href, label }) => {
  const logo = brand === brands.sats ? <Sats /> : <Elixia />;

  return (
    <div
      className={cn('logo', {
        'logo--dark': color === colors.dark,
        'logo--light': color === colors.light,
      })}
    >
      {href ? (
        <Link aria-label={label} className="logo__link" href={href}>
          {logo}
        </Link>
      ) : (
        logo
      )}
    </div>
  );
};

Logo.colors = colors;
Logo.brands = brands;

export default Logo;
