/// <reference lib="dom" />
import { useEffect, useState } from 'react';

import { tuple } from 'shared/tuple';

export default function useBoundingClientRect() {
  const [element, setElement] = useState<HTMLElement | null>();
  const [rect, setRect] = useState<DOMRect>();

  useEffect(() => {
    if (element) setRect(element.getBoundingClientRect());
  }, [element]);

  return tuple([setElement, rect]);
}
