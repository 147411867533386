import React from 'react';

/** Returns the value of `value` from the previous render.
```js
const [value, setValue] = useState();
const previousValue = usePrevious(value);
```
 */
export default function usePrevious<T>(
  value: T,
  initialValue?: T
): React.RefObject<T>['current'] {
  const state = React.useRef<T | null>(
    typeof initialValue === 'undefined' ? null : initialValue
  );

  React.useEffect(() => {
    state.current = value;
  }, [value]);

  return state.current;
}
