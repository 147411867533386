export const trackEvent = (data: any) => {
  window.dataLayer = window.dataLayer || [];
  if (Array.isArray(window.dataLayer)) {
    window.dataLayer.push(data);
  }
};

export const trackEvents = (events: any) => {
  if (Array.isArray(events)) {
    events.forEach(trackEvent);
  }
};
