import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import LogoElixia from 'sats-ui-lib/react/logos/elixia';
import LogoSats from 'sats-ui-lib/react/logos/sats';
import Message from 'sats-ui-lib/react/message';
import Text from 'sats-ui-lib/react/text';
import TextInput from 'sats-ui-lib/react/text-input';

import ContentContainer from 'components/content-container/content-container';
import HiddenInput from 'components/hidden-input/hidden-input';
import Spinner from 'components/spinner/spinner';

import { LoginPage as Props, brands } from './login-page.types';

const LoginPage: React.FunctionComponent<Props> = ({
  brand,
  endpoint,
  hiddenInputs,
  inputs,
  message,
  submitLabel,
  text,
  title,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    if (isLoading) {
      e.preventDefault();
    }

    setIsLoading(true);
  };

  return (
    <main className="login-page">
      <ContentContainer theme={ContentContainer.themes.narrow}>
        <div className="login-page__card">
          <div className="login-page__logo">
            {brand === brands.sats ? <LogoSats /> : <LogoElixia />}
          </div>
          <Text
            className="login-page__info"
            elementName="h1"
            size={Text.sizes.headline3}
          >
            {title}
          </Text>
          <div className="login-page__info">{text}</div>
          {message ? (
            <div className="login-page__info">
              <Message {...message} />
            </div>
          ) : null}
          <form action={endpoint} method="post" onSubmit={handleSubmit}>
            {isLoading ? <Spinner theme={Spinner.themes.overlay} /> : null}
            {hiddenInputs.map(input => (
              <HiddenInput {...input} key={input.name} />
            ))}
            <div className="login-page__inputs">
              {inputs.map(input => (
                <div key={input.name}>
                  <TextInput {...input} />
                </div>
              ))}
            </div>

            <Button role="button" type="submit" text={submitLabel} />
          </form>
        </div>
      </ContentContainer>
    </main>
  );
};

export default LoginPage;
