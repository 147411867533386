import Check from '@sats-group/icons/16/check';
import LocationIcon from '@sats-group/icons/24/location';
import React from 'react';

import { mod } from 'ts/add-bem-modifiers';

import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import BackgroundImage from 'components/background-image/background-image';
import Link from 'components/link/link';
import List from 'components/list/list';

import { ClubCard as Props } from './club-card.types';

const ClubCard: React.FunctionComponent<Props> = ({
  address,
  bullets,
  href,
  id,
  image,
  isChecked,
  linkButton,
  shouldShowCheckmark,
  textLink,
  title,
}) => {
  return React.createElement(
    href ? 'a' : 'div',
    { 'data-test-club': id, className: 'club-card', href },
    <React.Fragment>
      <div className="club-card__image">
        {image && <BackgroundImage {...image} />}
        {shouldShowCheckmark && (
          <div className={mod('club-card__checkmark', { checked: isChecked })}>
            {isChecked && <Check />}
          </div>
        )}
      </div>

      <div className="club-card__content">
        <Text
          className="club-card__title"
          elementName="h2"
          size={Text.sizes.headline3}
        >
          {title}
        </Text>

        {address && (
          <div className="club-card__address">
            <div className="club-card__address-icon">
              <LocationIcon />
            </div>
            {address}
          </div>
        )}

        {bullets.length > 0 && (
          <div className="club-card__bullets">
            <Text size={Text.sizes.small}>
              <List theme={List.themes.checkmarks}>{bullets}</List>
            </Text>
          </div>
        )}

        {/* NOTE: Don't show a link within a link */}
        {!href && linkButton && (
          <div className="club-card__link-button">
            <LinkButton {...linkButton} />
          </div>
        )}

        {!href && textLink && (
          <div className="club-card__text-link">
            <Link {...textLink} theme={Link.themes.orange} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ClubCard;
