import React from 'react';

import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import Link from 'components/link/link';
import Logo from 'components/logo/logo';

import { ClubsPage as Props } from './clubs-page.types';

const ClubsPage: React.FunctionComponent<Props> = ({ clubs, logo, title }) => (
  <main className="clubs-page">
    <ContentContainer theme={ContentContainer.themes.narrow}>
      <div className="clubs-page__content">
        <div className="clubs-page__section">
          <Logo {...logo} color={Logo.colors.dark} />
        </div>
        <div className="clubs-page__section">
          <Text
            elementName="h1"
            size={Text.sizes.basic}
            theme={Text.themes.normal}
          >
            {title}
          </Text>
        </div>
        <div className="clubs-page__section">
          <div className="clubs-page__clubs">
            {clubs.map(club => (
              <React.Fragment key={club.id}>
                <div className="clubs-page__id">
                  <Text>{club.id}</Text>
                </div>
                <div className="clubs-page__link">
                  <Link {...club.link} />
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </ContentContainer>
  </main>
);

export default ClubsPage;
