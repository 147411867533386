import { groupBy, isDefined } from './list';

export default (
  form: HTMLFormElement | null | undefined
): Record<string, any> => {
  if (!form) return {};

  const groupedEntries = Array.from(new FormData(form).entries()).reduce(
    groupBy(([key]) => key),
    {}
  );

  return Object.fromEntries(
    Object.entries(groupedEntries)
      .map(([key, entries]) => {
        // NOTE: If there are multiple values they are serialized to an array
        if (entries.length > 1) {
          return [key, entries.map(([_, value]) => value)];
        }

        // NOTE: Checkboxes with unique names are serialized to boolean
        if (
          form.querySelectorAll(`[type=checkbox][name='${key}']`).length === 1
        ) {
          return [
            key,
            form.querySelector<HTMLInputElement>(`[name='${key}']`)?.checked,
          ];
        }

        return entries[0];
      })
      .filter(isDefined)
  );
};
