/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';

import useToggle from 'client/hooks/use-toggle';
import WebAdminApiButton from 'client/components/web-admin-api-button/web-admin-api-button';
import WebAdminLayout from 'client/components/web-admin-layout/web-admin-layout';

import appBuildTime from 'shared/codegen/iso-now.codegen';
import currentGitCommitHash from 'shared/codegen/current-git-commit-hash.codegen';
import { replaceQueryParameters } from 'shared/replace-query-parameters';

import Button from 'sats-ui-lib/react/button';
import Checkbox from 'sats-ui-lib/react/checkbox';
import Message from 'sats-ui-lib/react/message';
import Text from 'sats-ui-lib/react/text';

import { WebAdminData as Props } from './web-admin-data.types';

const Code: React.FunctionComponent<{ children: string }> = ({ children }) => (
  <code
    style={{
      background: '#cfd8e4',
      borderRadius: '4px',
      padding: '2px 5px',
    }}
  >
    {children}
  </code>
);

const WebAdminData: React.FunctionComponent<Props> = ({
  buildAction,
  builtByVersion,
  dataBuildTime,
  getActions,
  layout,
  refreshActions,
  statusActions,
  statusMessages,
}) => {
  const [shouldForceRefreshData, toggleShouldForceRefreshData] =
    useToggle(false);
  const [shouldForceGetExternalData, toggleShouldForceGetExternalData] =
    useToggle(false);
  const [shouldForceBuildData, toggleShouldForceBuildData] = useToggle(false);

  const [appBuildLocalTime, setAppBuildLocalTime] = useState('calculating...');
  const [dataBuildLocalTime, setDataBuildLocalTime] =
    useState('calculating...');

  useEffect(() => {
    setAppBuildLocalTime(new Date(appBuildTime).toLocaleString());
    setDataBuildLocalTime(new Date(dataBuildTime).toLocaleString());
  }, []);

  return (
    <WebAdminLayout {...layout}>
      <div className="web-admin-data">
        {builtByVersion !== currentGitCommitHash ? (
          <Message
            inline
            text="Data built by a different app version than the one currently running."
            theme={Message.themes.warning}
          />
        ) : null}
        <div className="web-admin-data__meta">
          <div>
            <Text>
              Data built by app version <Code>{builtByVersion}</Code> at
            </Text>
            <ul>
              <li>
                <Code>{dataBuildTime}</Code> ISO
              </li>
              <li>
                <Code>{dataBuildLocalTime}</Code> local
              </li>
            </ul>
          </div>
          <div>
            <Text>
              Current app version <Code>{currentGitCommitHash}</Code> built at
            </Text>
            <ul>
              <li>
                <Code>{appBuildTime}</Code> ISO
              </li>
              <li>
                <Code>{appBuildLocalTime}</Code> local
              </li>
            </ul>
          </div>
        </div>

        <Text elementName="p">
          These actions are for the whole environment, regardless of language.
          Logging in to click these buttons on multiple URLs per environment
          (f.ex. <Code>beta.sats.no</Code> and <Code>beta.sats.se</Code>) is not
          necessary.
        </Text>

        <Text elementName="h3">Job Statuses</Text>
        <div>
          <Text elementName="p">
            If something crashes during a job, clearing the state of the job
            allows us to try again. Under normal circumstances, these are not
            needed.
          </Text>
          <div className="web-admin-data__data-statuses">
            {statusMessages.map(({ key, message, status }) => (
              <React.Fragment key={key}>
                <div>
                  <Text elementName="span">{message}</Text>
                </div>
                <div>
                  <Code>{status}</Code>
                </div>
              </React.Fragment>
            ))}
          </div>
          <div className="web-admin-data__actions">
            {statusActions.map(({ text, url }) => (
              <div key={url}>
                <WebAdminApiButton
                  text={text}
                  url={url}
                  variant={Button.variants.secondary}
                />
              </div>
            ))}
          </div>
        </div>

        <Text elementName="h3">Refresh Data</Text>
        <div>
          <Text elementName="p">
            A message to refresh data (get and then build) is added to a queue.
            This message is picked up and processed in the background.
            Refreshing will not happen immediately, but very soon. 30 to 60
            seconds is not uncommon.
          </Text>
          <br />
          <div>
            <Checkbox
              checked={shouldForceRefreshData}
              label="Force refresh, even if in progress"
              name="fake"
              onClick={toggleShouldForceRefreshData}
              value="fake"
            />
          </div>
          <div className="web-admin-data__actions">
            {refreshActions.map(({ text, url }, index) => (
              <div key={url}>
                <WebAdminApiButton
                  text={text}
                  url={replaceQueryParameters(url, {
                    force: shouldForceRefreshData,
                  })}
                  variant={index === 0 ? Button.variants.cta : undefined}
                />
              </div>
            ))}
          </div>
        </div>

        <Text elementName="h3">Get Data</Text>
        <div>
          <Text elementName="p">
            A message to get data from external sources is added to a queue.
            This message is picked up and processed in the background. It will
            not happen immediately, but very soon.
          </Text>
          <br />
          <div>
            <Checkbox
              checked={shouldForceGetExternalData}
              label="Force get, even if in progress"
              name="fake"
              onClick={toggleShouldForceGetExternalData}
              value="fake"
            />
          </div>
          <div className="web-admin-data__actions">
            {getActions.map(({ text, url }, index) => (
              <div key={url}>
                <WebAdminApiButton
                  text={text}
                  url={replaceQueryParameters(url, {
                    force: shouldForceGetExternalData,
                  })}
                  variant={index === 0 ? Button.variants.cta : undefined}
                />
              </div>
            ))}
          </div>
        </div>

        <Text elementName="h3">Build Data</Text>
        <div>
          <Text elementName="p">
            A message to build internal data from saved external data is added
            to a queue. This message is picked up and processed in the
            background. It will not happen immediately, but very soon.
          </Text>
          <br />
          <div>
            <Checkbox
              checked={shouldForceBuildData}
              label="Force build, even if in progress"
              name="fake"
              onClick={toggleShouldForceBuildData}
              value="fake"
            />
          </div>
          <div className="web-admin-data__actions">
            <div>
              <WebAdminApiButton
                text={buildAction.text}
                url={replaceQueryParameters(buildAction.url, {
                  force: shouldForceBuildData,
                })}
                variant={Button.variants.cta}
              />
            </div>
          </div>
        </div>
      </div>
    </WebAdminLayout>
  );
};

export default WebAdminData;
