import React from 'react';

import Message from 'sats-ui-lib/react/message';

import AccessLevelCard from 'components/access-level-card/access-level-card';
import CheckoutLayout from 'components/checkout-layout/checkout-layout';
import ClubCard from 'components/club-card/club-card';
import ContentContainer from 'components/content-container/content-container';
import CustomProductCard from 'components/custom-product-card/custom-product-card';
import List from 'components/list/list';

import { SelectAccessLevelPage as Props } from './select-access-level-page.types';

const SelectAccessLevelPage: React.FC<Props> = ({
  accessLevels = [],
  club,
  error,
  extraCards = [],
  layout,
}) => {
  return (
    <CheckoutLayout {...layout}>
      <div className="select-access-level-page">
        <ContentContainer>
          {error ? (
            <Message inline text={error} theme={Message.themes.error} />
          ) : null}
          {club ? <ClubCard {...club} /> : null}

          <div className="select-access-level-page__list">
            <List theme={List.themes.gridSmall}>
              {extraCards.map(item => (
                <CustomProductCard key={item.title} {...item} />
              ))}
              {accessLevels.map(item => (
                <AccessLevelCard key={item.title} {...item} />
              ))}
            </List>
          </div>
        </ContentContainer>
      </div>
    </CheckoutLayout>
  );
};

export default SelectAccessLevelPage;
