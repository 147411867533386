import React, { useEffect, useState } from 'react';

import { replaceQueryParameters } from 'shared/replace-query-parameters';
import { queryNames } from 'shared/membership-initiation-helpers';
import apiHelper from 'ts/api-helper';
import { publish } from 'ts/messages';
import useUrlState from 'hooks/use-url-state';

import Button from 'sats-ui-lib/react/button';
import { SelectOption } from 'sats-ui-lib/react/select-option/select-option.types';
import Select from 'sats-ui-lib/react/select';
import Text from 'sats-ui-lib/react/text';

import CheckoutLayout from 'components/checkout-layout/checkout-layout';
import CheckoutSummaryContent from 'components/checkout-summary-content/checkout-summary-content';
import CheckoutSummaryWrapper from 'components/checkout-summary-wrapper/checkout-summary-wrapper';
import ContentContainer from 'components/content-container/content-container';
import HiddenInput from 'components/hidden-input/hidden-input';
import InfoBox from 'components/info-box/info-box';
import RegistrationForm from 'components/registration-form/registration-form';
import Spinner from 'components/spinner/spinner';
import TextInterpolator from 'components/text-interpolator/text-interpolator';

import { RegistrationPage as Props } from './registration-page.types';

const RegistrationPage: React.FC<Props> = ({
  editorialCard,
  endpoint,
  errorMessages = [],
  form,
  initialStartDate,
  layout,
  priceExplanation,
  redirectUrl,
  startDates,
  startDateHeader,
  startDateText,
  startDateButtonText,
  summary,
}) => {
  const [checkoutSummary, setCheckoutSummary] = useState(summary);
  const [displayedStartDate, setDisplayedStartDate] =
    useState(initialStartDate);
  const [showStartDates, setShowStartDates] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [queryParameters, setQueryParameters] = useUrlState(true);
  const {
    clubId = '',
    memberTypeId = '',
    sessionId = '',
    startDate = '',
  } = queryParameters;

  // NOTE: `password` is only a thing in test environments.
  const onRegistered = async ({
    memberId,
    password,
    [queryNames.doPayment]: doPayment,
  }: {
    memberId: string;
    password?: string;
    [queryNames.doPayment]?: string;
  }) => {
    window.location.replace(
      replaceQueryParameters(redirectUrl, {
        ...queryParameters,
        memberId,
        password,
        [queryNames.doPayment]: doPayment,
      })
    );
  };

  const handleStartDatechange = (e: SelectOption | undefined) => {
    if (!e?.value) return;

    setIsLoading(true);

    apiHelper
      .post(endpoint, {
        ...queryParameters,
        startDate: e.value,
      })
      .then(
        ({
          summary,
          sessionId,
          startDate,
          formattedStartDate,
        }: {
          summary: Props['summary'];
          sessionId: string;
          startDate: string;
          formattedStartDate: string;
        }) => {
          setCheckoutSummary(summary);

          setDisplayedStartDate(formattedStartDate);

          setQueryParameters({ ...queryParameters, sessionId, startDate });
        }
      )
      .catch(error => {
        if (error && error.message) {
          publish({ text: error.message, theme: 'error' });
        }
      })
      .finally(() => setIsLoading(false));

    return;
  };

  useEffect(() => {
    errorMessages.forEach(errorMessage => publish(errorMessage));
  }, [errorMessages]);

  return (
    <CheckoutLayout {...layout}>
      <div className="registration-page">
        <ContentContainer>
          <div className="registration-page__content">
            {editorialCard && (
              <div className="registration-page__card">
                <InfoBox {...editorialCard} />
              </div>
            )}

            <div className="registration-page__main">
              {isLoading ? (
                <Spinner theme={Spinner.themes.centered} />
              ) : (
                <>
                  <div>
                    <Text elementName="h2" size={Text.sizes.headline3}>
                      {startDateHeader}
                    </Text>
                    <Text>
                      <TextInterpolator
                        template={startDateText}
                        tokens={{ date: displayedStartDate }}
                      />
                    </Text>
                    {startDates.length ? (
                      <div className="registration-page__start-date-input">
                        {showStartDates ? (
                          <Select
                            name={startDateHeader}
                            options={startDates}
                            onChangeOption={handleStartDatechange}
                            value={startDate}
                          />
                        ) : (
                          <Button
                            variant={Button.variants.secondary}
                            size={Button.sizes.small}
                            text={startDateButtonText}
                            onClick={() => setShowStartDates(true)}
                          />
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <RegistrationForm onResponse={onRegistered} {...form}>
                      <HiddenInput name="clubId" value={String(clubId)} />
                      <HiddenInput
                        name="memberTypeId"
                        value={String(memberTypeId)}
                      />
                      <HiddenInput name="sessionId" value={String(sessionId)} />
                    </RegistrationForm>
                  </div>
                </>
              )}
            </div>

            <div className="registration-page__summary">
              <CheckoutSummaryWrapper>
                <CheckoutSummaryContent
                  {...checkoutSummary}
                  isLoading={isLoading}
                />
                {priceExplanation ? (
                  <div className="registration-page__price-explanation">
                    <Text elementName="p" size={Text.sizes.small}>
                      {priceExplanation}
                    </Text>
                  </div>
                ) : null}
              </CheckoutSummaryWrapper>
            </div>
          </div>
        </ContentContainer>
      </div>
    </CheckoutLayout>
  );
};

export default RegistrationPage;
