import React, { useEffect, useRef } from 'react';

import focusRef from 'ts/focus-ref';
import usePrevious from 'hooks/use-previous';

import { TabTrapper as Props } from './tab-trapper.types';

const TabTrapper: React.FunctionComponent<Props> = ({ children, isActive }) => {
  const wasActive = usePrevious(isActive);
  const contentRef = useRef<HTMLDivElement>(null);
  const previouslyFocusedElement = useRef<HTMLElement | null>(null);

  const focusContent = () => focusRef(contentRef);

  useEffect(() => {
    if (!wasActive && isActive) {
      // NOTE: Because document.activeElement is an Element by default, we need
      // to cast this as a HTMLElement to be able to use HTMLElement functions
      previouslyFocusedElement.current = document.activeElement as HTMLElement;
      focusContent();
    }

    if (wasActive && !isActive) {
      focusRef(previouslyFocusedElement);
    }
  }, [isActive]);

  // NOTE: This happens on unmount only
  useEffect(() => () => focusRef(previouslyFocusedElement), []);

  return (
    <>
      {isActive && <div onFocus={focusContent} tabIndex={0} />}
      <div ref={contentRef} tabIndex={-1}>
        {children}
      </div>
      {isActive && <div onFocus={focusContent} tabIndex={0} />}
    </>
  );
};

export default TabTrapper;
