import { isDefined } from 'list-fns';
import React, { useState } from 'react';
import Transition from 'react-tiny-transition';

import { publish } from 'ts/messages';
import { types } from 'ts/messages.types';
import { replaceQueryParameters } from 'shared/replace-query-parameters';
import apiHelper from 'ts/api-helper';
import useUrlState from 'hooks/use-url-state';

import LinkButton from 'sats-ui-lib/react/link-button';
import Message from 'sats-ui-lib/react/message';
import Text from 'sats-ui-lib/react/text';
import VisuallyHidden from 'sats-ui-lib/react/visually-hidden';

import CheckoutLayout from 'components/checkout-layout/checkout-layout';
import CheckoutSummaryContent from 'components/checkout-summary-content/checkout-summary-content';
import CheckoutSummaryWrapper from 'components/checkout-summary-wrapper/checkout-summary-wrapper';
import ContentContainer from 'components/content-container/content-container';
import DiscountCodeInput from 'components/discount-code-input/discount-code-input';
import List from 'components/list/list';
import MembershipCard from 'components/membership-card/membership-card';
import RichText from 'components/rich-text/rich-text';
import Spinner from 'components/spinner/spinner';
import StickyContainer from 'components/sticky-container/sticky-container';

import { SelectMembershipPage as Props } from './select-membership-page.types';

const groupId = (key: string) => `option-group-${key}`;

const CustomSpinner: React.FC<{ isLoading: boolean }> = ({ isLoading }) => (
  <Transition duration={300}>
    {isLoading ? (
      <div className="select-membership-page__spinner">
        <div className="select-membership-page__spinner-icon">
          <Spinner />
        </div>
      </div>
    ) : null}
  </Transition>
);

// eslint-disable-next-line react/no-multi-comp
const SelectMembershipPage: React.FC<Props> = ({
  campaignCodeInput,
  corporateCodeInput,
  endpoint,
  error,
  layout,
  linkUrl,
  linkText,
  options = [],
  summary,
  text,
  tryAgainText,
}) => {
  const [query, setQuery] = useUrlState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currentOptions, setOptions] = useState(options);
  const [currentLinkUrl, setLinkUrl] = useState(linkUrl);
  const [checkoutSummary, setCheckoutSummary] = useState(summary);

  const updateOptions = (
    newQuery: Parameters<typeof replaceQueryParameters>[1]
  ) => {
    setIsLoading(true);
    apiHelper
      .get(replaceQueryParameters(endpoint, newQuery))
      .then(
        ({
          linkUrlQuery,
          membershipId,
          options = [],
          summary,
        }: {
          linkUrlQuery: Record<string, string>;
          membershipId: string;
          options: Props['options'];
          summary: Props['summary'];
        }) => {
          setOptions(options);
          setCheckoutSummary(summary);

          if (summary && Array.isArray(summary.messages)) {
            summary.messages.forEach(message => publish(message));
          }

          if (linkUrlQuery) {
            setLinkUrl(replaceQueryParameters(linkUrl, linkUrlQuery));
          }

          // NOTE: We don't put the choice in the URL until we know whether the API call succeeded to avoid storing invalid choices between page loads
          setQuery({ ...newQuery, membershipId });
        }
      )
      .catch(error => {
        if (error && error.message) {
          publish({ text: error.message, theme: types.error });
        }
      })
      .finally(() => setIsLoading(false));
  };

  const chooseSingle = (key: string, value: string) =>
    updateOptions({ ...query, [key]: value });

  const chooseMulti = (key: string, value: string) => {
    // NOTE: We have to do some type gymnastics here because if there's exactly one selected option the value is a string instead of a list
    const existingQueryValue = query[key];
    const values = Array.isArray(existingQueryValue)
      ? existingQueryValue
      : [existingQueryValue].filter(isDefined);

    const newValues = values.includes(value)
      ? values.filter(v => v !== value)
      : values.concat(value);

    updateOptions({ ...query, [key]: newValues });
  };

  return (
    <CheckoutLayout {...layout}>
      <div className="select-membership-page" data-test-is-loading={isLoading}>
        <ContentContainer>
          {error ? (
            <div className="select-membership-page__error">
              <Message
                inline
                link={{
                  href: '',
                  text: tryAgainText,
                }}
                text={error}
                theme={Message.themes.error}
              />
            </div>
          ) : (
            <React.Fragment>
              <div className="select-membership-page__content">
                <div>
                  <div className="select-membership-page__controls">
                    <CustomSpinner isLoading={isLoading} />
                    <div className="select-membership-page__options">
                      {currentOptions.map(
                        (
                          { cards = [], emptyText, isMultiSelect, key, title },
                          groupIndex
                        ) => {
                          const titleElement = (
                            <Text
                              className="select-membership-page__option-group-title"
                              elementName="h2"
                              theme={Text.themes.normal}
                            >
                              {title}
                            </Text>
                          );

                          return (
                            <div
                              className="select-membership-page__option-group"
                              data-test-option-group-multi={isMultiSelect}
                              key={title}
                              id={groupId(key)}
                            >
                              {groupIndex === 0 ? (
                                <VisuallyHidden>{titleElement}</VisuallyHidden>
                              ) : (
                                titleElement
                              )}

                              {cards.length === 0 && emptyText && (
                                <div className="select-membership-page__empty-list">
                                  {emptyText}
                                </div>
                              )}

                              <List theme={List.themes.marginLarge}>
                                {cards.map((card, cardIndex) => (
                                  <button
                                    className="select-membership-page__option"
                                    data-test-option={card.id}
                                    data-test-selected={Boolean(
                                      card.isSelected
                                    )}
                                    onClick={
                                      card.isSelected && !isMultiSelect
                                        ? undefined
                                        : () =>
                                            isMultiSelect
                                              ? chooseMulti(key, card.id)
                                              : chooseSingle(key, card.id)
                                    }
                                    key={card.id}
                                  >
                                    <MembershipCard
                                      isMultiSelect={isMultiSelect}
                                      theme={
                                        isMultiSelect || cardIndex === 0
                                          ? undefined
                                          : MembershipCard.themes.simple
                                      }
                                      {...card}
                                    />
                                  </button>
                                ))}
                              </List>
                            </div>
                          );
                        }
                      )}
                    </div>

                    {campaignCodeInput ? (
                      <div className="select-membership-page__discount-code">
                        <DiscountCodeInput
                          {...campaignCodeInput}
                          onClick={chooseSingle}
                          appliedCode={checkoutSummary?.appliedCampaign}
                          isLoading={isLoading}
                        />
                      </div>
                    ) : null}

                    {corporateCodeInput ? (
                      <div className="select-membership-page__discount-code">
                        <DiscountCodeInput
                          {...corporateCodeInput}
                          onClick={chooseSingle}
                          appliedCode={checkoutSummary?.appliedCorporateCode}
                          isLoading={isLoading}
                        />
                      </div>
                    ) : null}

                    <div className="select-membership-page__link-wrapper">
                      <LinkButton
                        data-test-select-membership-next
                        className="select-membership-page__link"
                        variant={LinkButton.variants.cta}
                        href={currentLinkUrl}
                        text={linkText}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  {checkoutSummary ? (
                    <StickyContainer>
                      <CheckoutSummaryWrapper>
                        <CustomSpinner isLoading={isLoading} />
                        <CheckoutSummaryContent
                          {...checkoutSummary.checkoutSummaryContent}
                          button={{
                            href: currentLinkUrl,
                            text: linkText,
                          }}
                        />
                      </CheckoutSummaryWrapper>
                    </StickyContainer>
                  ) : null}
                </div>
              </div>

              {text && (
                <div className="select-membership-page__content">
                  <div className="select-membership-page__text">
                    <RichText theme={RichText.themes.orangeLinks} {...text} />
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        </ContentContainer>
      </div>
    </CheckoutLayout>
  );
};

export default SelectMembershipPage;
