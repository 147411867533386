import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';

import { ThemedComponent } from 'client/types';

import { mod } from 'ts/add-bem-modifiers';

import { renderOptions } from './rich-text-renderer-options';
import { RichText as Props, themes } from './rich-text.types';

const RichText: ThemedComponent<Props, typeof themes> & {
  renderOptions: typeof renderOptions;
} = ({ className, content, renderOption = renderOptions.default, theme }) => {
  return (
    <div className={[mod('rich-text', theme), className].join(' ')}>
      {content ? documentToReactComponents(content, renderOption) : null}
    </div>
  );
};

RichText.themes = themes;
RichText.renderOptions = renderOptions;

export default RichText;
