import React, { useEffect, useRef } from 'react';

import useMessage from 'hooks/use-message';
import { types } from 'ts/messages.types';

import Message from 'sats-ui-lib/react/message';

import ContentContainer from 'components/content-container/content-container';

import { DynamicMessage as Props } from './dynamic-message.types';

const DynamicMessage: React.FunctionComponent<Props> = ({
  initialMessage,
  closeText,
}) => {
  const timerRef = useRef<number>();
  const [message, clearMessage] = useMessage(initialMessage);

  useEffect(() => {
    if (message && message.theme === types.success) {
      timerRef.current = setTimeout(clearMessage, 10000);
    }

    return () => clearTimeout(timerRef.current);
  }, [message]);

  // NOTE: Always make sure that the live region is present in the document first, and only then dynamically add/change any content. (https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions)
  return (
    <ContentContainer aria-live="polite" className="dynamic-message">
      {message ? (
        <Message
          action={{
            elementName: 'button',
            onClick: clearMessage,
            text: closeText,
          }}
          {...message}
        />
      ) : null}
    </ContentContainer>
  );
};

export default DynamicMessage;
