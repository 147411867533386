import React from 'react';

export default () => (
  <svg
    fill="currentColor"
    viewBox="0 0 167 51"
    height="22"
    width="72"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0,42.9L9.1,36c2.4,3.1,5.8,4.7,9.7,4.7c4.7,0,6.5-2.6,6.5-5.1c0-1.8-0.9-3.1-3.3-5.1l-3.3-3
    c-3.5-3-6-6.2-6-10.9c0-9.5,8-16.6,18.9-16.6c8,0,13.3,3.6,15.5,7.1L38.1,14c-2.4-2.6-4.9-3.6-7.7-3.6c-3.8,0-5.4,2.2-5.4,4.5
    c0,1.8,1.1,3.3,3.1,4.9l3.3,3c3.3,2.8,6.2,5.8,6.2,10.9c0,9.9-7.3,17.3-19.6,17.3C9.5,51.3,3.5,47.6,0,42.9z"
    />
    <path
      d="M119.8,42.9l9.1-6.9c2.4,3.1,5.8,4.7,9.7,4.7c4.7,0,6.5-2.6,6.5-5.1c0-1.8-0.9-3.1-3.3-5.1l-3.3-3
    c-3.5-3-6-6.2-6-10.9c0-9.5,8-16.6,18.9-16.6c8,0,13.3,3.6,15.5,7.1l-9.1,6.9c-2.4-2.6-4.9-3.6-7.7-3.6c-3.8,0-5.4,2.2-5.4,4.5
    c0,1.8,1.1,3.3,3.1,4.9l3.3,3c3.3,2.8,6.2,5.8,6.2,10.9c0,9.9-7.3,17.3-19.6,17.3C129.3,51.3,123.1,47.6,119.8,42.9z"
    />
    <path d="M127.3,11.6h-11.6l-10.9,38.6h-12l10.9-38.6H91.2l3-10.4h36.2L127.3,11.6z" />
    <path d="M83.2,1.4v49H72.7V40.2H56.2l-5.6,10.2H37.9l29-49H83.2z M72.7,29.7V10.7L62.1,29.7H72.7z" />
  </svg>
);
