import React, { useRef, useState } from 'react';

import apiHelper from 'ts/api-helper';
import { addSlugs } from 'shared/add-slugs';

import Text from 'sats-ui-lib/react/text';
import TextInput from 'sats-ui-lib/react/text-input';

import HiddenInput from 'components/hidden-input/hidden-input';
import PrefixInput from 'components/prefix-input/prefix-input';
import RadioList from 'components/radio-list/radio-list';

import { RegistrationFormInputs as Props } from './registration-form.types';

const RegistrationFormInputs: React.FunctionComponent<Props> = ({
  address,
  birthDateTitle,
  birthDay,
  birthMonth,
  birthYear,
  email,
  firstName,
  lastName,
  passwordText,
  passwordTitle,
  phone,
  postalAreaEndpoint,
  postalAreaName,
  postalCode,
  sex,
  socialSecurityNumber,
  title,
}) => {
  const [postalArea, setPostalArea] = useState('');
  const postalCodeValue = useRef('');

  const handlePostalCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    setPostalArea('');

    if (value.length <= 3) {
      return;
    }

    postalCodeValue.current = value;

    apiHelper
      .get(addSlugs(postalAreaEndpoint, [value]))
      .then(data => {
        if (postalCodeValue.current === value) {
          setPostalArea(data.postalArea);
        }
      })
      .catch(() => {});
  };

  return (
    <>
      {title ? (
        <Text
          elementName="h4"
          size={Text.sizes.large}
          theme={Text.themes.emphasis}
        >
          {title}
        </Text>
      ) : null}
      <TextInput
        {...firstName}
        autoComplete="given-name"
        required
        hasMovingLabel={true}
      />
      <TextInput
        {...lastName}
        autoComplete="family-name"
        required
        hasMovingLabel={true}
      />
      <TextInput
        {...email}
        autoComplete="email"
        required
        type="email"
        hasMovingLabel={true}
      />
      {birthDateTitle && birthDay && birthMonth && birthYear ? (
        <fieldset className="registration-form__group">
          <summary>{birthDateTitle}</summary>
          <div className="registration-form__group-inputs">
            <TextInput
              {...birthDay}
              required
              hasMovingLabel={true}
              className="registration-form__date-input"
            />
            <TextInput
              {...birthMonth}
              required
              hasMovingLabel={true}
              className="registration-form__date-input"
            />
            <TextInput
              {...birthYear}
              required
              hasMovingLabel={true}
              className="registration-form__date-input"
            />
          </div>
        </fieldset>
      ) : null}
      {sex && (
        <RadioList required theme={RadioList.themes.horizontal} {...sex} />
      )}
      {socialSecurityNumber && (
        <TextInput {...socialSecurityNumber} required hasMovingLabel={true} />
      )}

      {address && (
        <div className="registration-form__address">
          <TextInput
            {...address}
            autoComplete="street-address"
            required
            hasMovingLabel={true}
          />
        </div>
      )}

      {postalCode && (
        <div className="registration-form__postal-code">
          <div>
            <TextInput
              {...postalCode}
              autoComplete="postal-code"
              required
              hasMovingLabel={true}
              onChange={handlePostalCodeChange}
            />
          </div>

          <div className="registration-form__postal-area">
            <HiddenInput value={postalArea} name={postalAreaName} />
            {postalArea && (
              <Text
                elementName="h2"
                size={Text.sizes.small}
                theme={Text.themes.normal}
              >
                {postalArea}
              </Text>
            )}
          </div>
        </div>
      )}

      {passwordText && (
        <div className="registration-form__password">
          <Text
            className="registration-form__password-title"
            elementName="h2"
            size={Text.sizes.basic}
            theme={Text.themes.normal}
          >
            {passwordTitle}
          </Text>
          <Text elementName="p" size={Text.sizes.small}>
            {passwordText}
          </Text>
        </div>
      )}

      {phone && (
        <PrefixInput
          {...phone}
          autoComplete="tel-national"
          required
          type="tel"
          hasMovingLabel={true}
        />
      )}
    </>
  );
};

export default RegistrationFormInputs;
