import { Document } from '@contentful/rich-text-types';

import { ObjectValues } from 'client/types';

import { renderOptions } from './rich-text-renderer-options';

export const themes = {
  greenCheckmarkList: 'green-checkmark-list',
  orangeLinks: 'orange-links',
  whiteCheckmarkList: 'white-checkmark-list',
} as const;

export type RichText = {
  className?: string;
  content?: Document;
  renderOption?: ObjectValues<typeof renderOptions>;
  theme?: ObjectValues<typeof themes>;
};
