import React from 'react';

export default () => (
  <svg
    width="99"
    height="22"
    viewBox="0 0 229 52"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M24.4082 10.8245L21.8612 19.7388H37.7796L34.8082 30.7755H18.6776L15.9184 40.751H37.5674L34.3837 51.7877H0L14.6449 0H49.0286L45.8449 11.0367H24.4082V10.8245Z" />
    <path d="M80.0163 40.9633L76.8327 52H43.7225L58.3673 0.21225H71.102L59.6408 41.1755H80.0163V40.9633Z" />
    <path d="M99.5429 0H112.278L97.6327 51.7877H84.898L99.5429 0Z" />
    <path d="M143.69 26.5306L152.392 51.7877H139.445L134.139 35.4449L120.343 51.7877H105.698L129.257 24.8326L120.555 0H133.502L138.808 15.9184L152.18 0H166.825L143.69 26.5306Z" />
    <path d="M174.465 0H187.2L172.555 51.7877H159.82L174.465 0Z" />
    <path d="M228.376 0V51.7877H217.127V40.9633H199.935L193.992 51.7877H180.408L211.184 0H228.376ZM217.127 29.9265V9.76326L205.878 29.9265H217.127Z" />
  </svg>
);
