import React from 'react';

import { ThemedComponent } from 'client/types';

import { mod } from 'ts/add-bem-modifiers';

import Text from 'sats-ui-lib/react/text';

import { Price as Props, colors, sizes, themes } from './price.types';

const Price: ThemedComponent<Props, typeof themes> & {
  colors: typeof colors;
  sizes: typeof sizes;
} & {} = ({ amount, color, currency, label, period, size, theme }) => {
  return (
    <div className={mod('price', color, theme)}>
      <div className="price__content">
        <Text className="price__label" size={Text.sizes.small}>
          {label}
        </Text>
        <Text
          className="price__amount"
          size={size ? size : Text.sizes.headline3}
          theme={Text.themes.headline}
        >
          {amount}
        </Text>
        {currency || period ? (
          <Text size={Text.sizes.small} className="price__currency">
            {currency && period ? (
              <span>
                {currency} {period}
              </span>
            ) : currency && !period ? (
              currency
            ) : !currency && period ? (
              period
            ) : null}
          </Text>
        ) : null}
      </div>
    </div>
  );
};

Price.colors = colors;
Price.sizes = sizes;
Price.themes = themes;

export default Price;
