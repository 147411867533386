import React from 'react';

import useIsMounted from 'hooks/use-is-mounted';

import Spinner from 'components/spinner/spinner';

import { ClientOnlyForm as Props } from './client-only-form.types';

const ClientOnlyForm: React.FunctionComponent<Props> = ({
  children,
  ...restProps
}) => {
  const isMounted = useIsMounted();

  return isMounted ? (
    <form {...restProps}>{children}</form>
  ) : (
    <div className="client-only-form__fallback">
      <Spinner />
    </div>
  );
};

export default ClientOnlyForm;
