import React from 'react';

import ContentContainer from 'client/components/content-container/content-container';
import HiddenInput from 'client/components/hidden-input/hidden-input';
import Link from 'client/components/link/link';
import Logo from 'client/components/logo/logo';

import Button from 'sats-ui-lib/react/button';
import Text from 'sats-ui-lib/react/text';
import TextInput from 'sats-ui-lib/react/text-input';

import { HelperPage as Props } from './helper-page.types';

const HelperPage: React.FunctionComponent<Props> = ({
  form,
  logo,
  skip,
  title,
}) => (
  <div className="helper-page">
    <ContentContainer theme={ContentContainer.themes.veryNarrow}>
      <form action={form.endpoint} className="helper-page__form" method="POST">
        <div className="helper-page__logo">
          <Logo {...logo} color={Logo.colors.dark} />
        </div>
        <Text
          elementName="h1"
          size={Text.sizes.basic}
          theme={Text.themes.normal}
        >
          {title}
        </Text>
        <HiddenInput {...form.club} />
        <TextInput {...form.helper} hasMovingLabel required />
        <div>
          <Button text={form.submit} type="submit" wide />
        </div>
        <div className="helper-page__skip">
          <Link data-test-skip-helper {...skip} />
        </div>
      </form>
    </ContentContainer>
  </div>
);

export default HelperPage;
