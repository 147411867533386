import React from 'react';

import CheckoutLayout from 'client/components/checkout-layout/checkout-layout';
import ContentContainer from 'client/components/content-container/content-container';
import List from 'client/components/list/list';

import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import { ParkedPage as Props } from './parked-page.types';

const ParkedPage: React.FunctionComponent<Props> = ({
  layout,
  link,
  list,
  title,
}) => (
  <CheckoutLayout {...layout}>
    <div className="parked-page">
      <ContentContainer theme={ContentContainer.themes.narrow}>
        <div>
          <Text
            elementName="h2"
            size={Text.sizes.headline2}
            theme={Text.themes.emphasis}
            className="parked-page__title"
          >
            {title}
          </Text>
          <List theme={List.themes.checkmarks}>{list}</List>
        </div>
        <div className="parked-page__button">
          <LinkButton {...link} />
        </div>
      </ContentContainer>
    </div>
  </CheckoutLayout>
);

export default ParkedPage;
