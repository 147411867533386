import React from 'react';

import CheckoutLayout from 'components/checkout-layout/checkout-layout';
import ClubSearch from 'components/club-search/club-search';
import ContentContainer from 'components/content-container/content-container';

import { SelectClubPage as Props } from './select-club-page.types';

const SelectClubPage: React.FC<Props> = ({ layout, search }) => {
  return (
    <CheckoutLayout {...layout}>
      <div className="select-club-page">
        <ContentContainer>
          <ClubSearch {...search} />
        </ContentContainer>
      </div>
    </CheckoutLayout>
  );
};

export default SelectClubPage;
