import { createElement } from 'react';
import { hydrateRoot } from 'react-dom/client';

import getValueFromAttribute from 'ts/get-value-from-attribute';
import pageComponents from 'shared/codegen/page-components.codegen';

(() => {
  const componentName = getValueFromAttribute('data-component-name');
  if (!componentName) {
    // NOTE: This should never happen
    return;
  }

  const mountPoint = document.getElementById('mount-point');
  const component = pageComponents[componentName];
  const props = window.__INITIAL_PROPS__;
  if (!props || !mountPoint || !component) {
    // NOTE: This should never happen
    return;
  }

  hydrateRoot(mountPoint, createElement(component, props));
  document.documentElement.setAttribute('data-hydrated', '');
})();
