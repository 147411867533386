import cn from 'classnames';
import React from 'react';

import Text from 'sats-ui-lib/react/text';

import BackgroundImage from 'components/background-image/background-image';
import CheckoutLayoutHeader from 'components/checkout-layout-header/checkout-layout-header';
import CheckoutProgress from 'components/checkout-progress/checkout-progress';
import CheckoutSessionModal from 'components/checkout-session-modal/checkout-session-modal';
import ContentContainer from 'components/content-container/content-container';
import Countdown from 'components/countdown/countdown';
import DynamicMessage from 'components/dynamic-message/dynamic-message';

import CheckoutSessionContextProvider, {
  useCheckoutSessionContext,
} from 'contexts/checkout-session-context-provider';

import { CheckoutLayout as Props } from './checkout-layout.types';

const InnerCheckoutLayout: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({
  children,
  countdown,
  image,
  isSessionExpired = false,
  header,
  message,
  progress,
  sessionModal,
  subTitle,
  title,
  text,
}) => {
  const { isExpired } = useCheckoutSessionContext();

  return (
    <React.Fragment>
      {isExpired || isSessionExpired ? (
        <CheckoutSessionModal {...sessionModal} />
      ) : null}

      <div className="checkout-layout">
        <DynamicMessage {...message} />
        <CheckoutLayoutHeader {...header} />

        <main>
          <div
            className={cn('checkout-layout__top', {
              'checkout-layout__top--countdown': countdown,
            })}
          >
            {image && (
              <BackgroundImage className="checkout-layout__image" {...image} />
            )}
            {countdown ? (
              <div className="checkout-layout__countdown">
                <Countdown {...countdown} />
              </div>
            ) : null}
            <ContentContainer>
              {progress && <CheckoutProgress {...progress} />}
              <div className="checkout-layout__text">
                <Text elementName="h1" theme={Text.themes.headline}>
                  {title}
                </Text>
                {subTitle && (
                  <Text size={Text.sizes.headline3}>{subTitle}</Text>
                )}
                {text && (
                  <Text className="checkout-layout__text-text">{text}</Text>
                )}
              </div>
            </ContentContainer>
          </div>

          <div className="checkout-layout__content">{children}</div>
        </main>
      </div>
    </React.Fragment>
  );
};

// eslint-disable-next-line react/no-multi-comp
const CheckoutLayout: React.FunctionComponent<Props> = ({ ...rest }) => (
  <CheckoutSessionContextProvider>
    <InnerCheckoutLayout {...rest} />
  </CheckoutSessionContextProvider>
);

export default CheckoutLayout;
