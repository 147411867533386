type ErrorMessages = {
  error: string;
  refused: string;
};

export default (
  resultCode: string,
  errors: ErrorMessages
): { success: true } | { success: false; error: string } => {
  switch (resultCode) {
    case 'Authorised': {
      return { success: true };
    }
    case 'Error': {
      return { success: false, error: errors.error };
    }
    case 'Refused': {
      return { success: false, error: errors.refused };
    }
    default: {
      return { success: false, error: errors.error };
    }
  }
};
