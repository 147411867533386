import React from 'react';

import { ThemedComponent } from 'client/types';

import { mod } from 'ts/add-bem-modifiers';

import { ContentContainer as Props, themes } from './content-container.types';

const ContentContainer: ThemedComponent<Props, typeof themes> = ({
  children,
  className,
  theme,
  ...rest
}) => {
  return (
    <div
      className={[mod('content-container', theme), className].join(' ')}
      {...rest}
    >
      {children}
    </div>
  );
};

ContentContainer.themes = themes;

export default ContentContainer;
