import React from 'react';

import TextInput from 'sats-ui-lib/react/text-input';

import { PrefixInput as Props } from './prefix-input.types';

const PrefixInput: React.FunctionComponent<Props> = ({
  prefix,
  ...inputProps
}) => {
  return (
    <div className="prefix-input">
      {prefix && <div className="prefix-input__prefix">{prefix}</div>}
      <div className="prefix-input__input">
        <TextInput {...inputProps} />
      </div>
    </div>
  );
};

export default PrefixInput;
