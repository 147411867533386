import React from 'react';

import WebAdminLayout from 'client/components/web-admin-layout/web-admin-layout';

import SitemapNode from './sitemap-node';
import { WebAdminSitemap as Props } from './web-admin-sitemap.types';

const WebAdminSitemap: React.FunctionComponent<Props> = ({
  layout,
  sitemap,
}) => (
  <WebAdminLayout {...layout}>
    <div className="web-admin-sitemap">
      <SitemapNode data={sitemap} />
    </div>
  </WebAdminLayout>
);

export default WebAdminSitemap;
