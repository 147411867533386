import type { Message } from 'sats-ui-lib/react/message/message.types';
import { themes } from 'sats-ui-lib/react/message/message.types';

export const isMessageType = (
  maybeMessageType?: string
): maybeMessageType is Message['theme'] =>
  maybeMessageType
    ? Boolean(themes[maybeMessageType as keyof Message['theme']])
    : false;

export const types: Record<string, Message['theme']> = {
  default: themes.default,
  error: themes.error,
  success: themes.success,
  warning: themes.warning,
};

export type MessageDetail = Pick<Message, 'link' | 'text' | 'theme'>;

export type Listener = (event: MessageDetail) => void;
