import React, { useState } from 'react';

import apiHelper from 'ts/api-helper';

import { CheckoutSessionContext as CheckoutSessionContextTypes } from './checkout-session-context-provider.types';

const CheckoutSessionContext = React.createContext<CheckoutSessionContextTypes>(
  {
    isExpired: false,
    checkIsExpired: () => {},
  }
);

const CheckoutSessionContextProvider: React.FunctionComponent<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const [isExpired, setIsExpired] = useState(false);

  /** An override for `apiHelper.overrideStatus`. Many Service Platform endpoints return `422` when the current checkout session has expired */
  const sessionExpiredOverride = {
    422: () => {
      setIsExpired(true);
      return true;
    },
  };

  const checkIsExpired = apiHelper.overrideStatus(sessionExpiredOverride);

  return (
    <CheckoutSessionContext.Provider value={{ checkIsExpired, isExpired }}>
      {children}
    </CheckoutSessionContext.Provider>
  );
};

export const useCheckoutSessionContext = () =>
  React.useContext(CheckoutSessionContext);

export default CheckoutSessionContextProvider;
