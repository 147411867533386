import React, { useEffect, useLayoutEffect, useState } from 'react';

import clamp from 'client/ts/clamp';

import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';

import { Countdown as Props } from './countdown.types';

type TimeValues = Pick<Props, 'days' | 'hours' | 'minutes'> & {
  secondsLeft: number;
};

const toTimeValues = (secondsLeft: number): TimeValues => ({
  days: secondsLeft < 86400 ? 0 : Math.floor(secondsLeft / 86400),
  hours: secondsLeft < 3600 ? 0 : Math.floor(secondsLeft / 3600) % 24,
  minutes: secondsLeft < 60 ? 0 : Math.floor(secondsLeft / 60) % 60,
  secondsLeft,
});

const nowInSeconds = () => Math.floor(Date.now() / 1000);

const Countdown: React.FunctionComponent<Props> = ({
  days,
  daysLabel,
  daysLabelPlural,
  hours,
  hoursLabel,
  hoursLabelPlural,
  minutes,
  minutesLabel,
  minutesLabelPlural,
  list,
  timeTitle,
  title,
  totalSeconds,
}) => {
  const [timeValues, setTimeValues] = useState<TimeValues>({
    days,
    hours,
    minutes,
    secondsLeft: totalSeconds,
  });

  const [endTime, setEndTime] = useState<number>();

  useEffect(() => {
    setEndTime(nowInSeconds() + totalSeconds);
  }, []);

  useLayoutEffect(() => {
    if (!endTime) {
      return;
    }

    const newTimeValues = toTimeValues(
      clamp(endTime - nowInSeconds(), 0, endTime)
    );

    const ref = requestAnimationFrame(() => {
      setTimeValues(newTimeValues);
    });

    return () => cancelAnimationFrame(ref);
  }, [endTime, timeValues]);

  return (
    <div className="countdown" data-total-seconds={totalSeconds}>
      <div className="countdown__ribbon">
        <ContentContainer className="countdown__ribbon-content">
          <Text size={Text.sizes.small} theme={Text.themes.emphasis}>
            {title}
          </Text>
          {list.length ? (
            <ul className="countdown__list">
              {list.map(text => (
                <li key={text}>
                  <Text size={Text.sizes.interface}>{text}</Text>
                </li>
              ))}
            </ul>
          ) : null}
        </ContentContainer>
      </div>
      <ContentContainer>
        <div className="countdown__counter">
          <Text
            className="countdown__counter-title"
            size={Text.sizes.small}
            theme={Text.themes.emphasis}
          >
            {timeTitle}
          </Text>
          <div className="countdown__counter-items">
            <div>
              <Text size={Text.sizes.basic} theme={Text.themes.emphasis}>
                {timeValues.days}
              </Text>
              <Text size={Text.sizes.interface}>
                {timeValues.days === 1 ? daysLabel : daysLabelPlural}
              </Text>
            </div>
            <div aria-hidden>
              <Text size={Text.sizes.basic} theme={Text.themes.emphasis}>
                :
              </Text>
            </div>
            <div>
              <Text size={Text.sizes.basic} theme={Text.themes.emphasis}>
                {timeValues.hours}
              </Text>
              <Text size={Text.sizes.interface}>
                {timeValues.hours === 1 ? hoursLabel : hoursLabelPlural}
              </Text>
            </div>
            <div aria-hidden>
              <Text size={Text.sizes.basic} theme={Text.themes.emphasis}>
                :
              </Text>
            </div>
            <div>
              <Text size={Text.sizes.basic} theme={Text.themes.emphasis}>
                {timeValues.minutes}
              </Text>
              <Text size={Text.sizes.interface}>
                {timeValues.minutes === 1 ? minutesLabel : minutesLabelPlural}
              </Text>
            </div>
          </div>
        </div>
      </ContentContainer>
    </div>
  );
};

export default Countdown;
