import React from 'react';

import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import CheckoutLayout from 'components/checkout-layout/checkout-layout';
import ContentContainer from 'components/content-container/content-container';

import { AutomaticLogoutPage as Props } from './automatic-logout-page.types';

/* eslint-disable react/no-multi-comp */
const AutomaticLogoutPage: React.FunctionComponent<Props> = ({
  layout,
  links,
  text,
  title,
}) => (
  <CheckoutLayout {...layout}>
    <div className="automatic-logout-page">
      <ContentContainer
        className="automatic-logout-page__content"
        theme={ContentContainer.themes.veryNarrow}
      >
        <Text elementName="h1">{title}</Text>
        <Text size={Text.sizes.large}>{text}</Text>
        {links.map((link, index) => (
          <LinkButton
            key={link.href}
            variant={
              index === 0
                ? LinkButton.variants.primary
                : LinkButton.variants.secondary
            }
            {...link}
          />
        ))}
      </ContentContainer>
    </div>
  </CheckoutLayout>
);

export default AutomaticLogoutPage;
