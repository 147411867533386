import React from 'react';

import type { FriendConfirmationPage as Props } from './friend-confirmation-page.types';

import SimpleConfirmationPage from '../simple-confirmation-page/simple-confirmation-page';

const FriendConfirmationPage: React.FC<Props> = props => (
  <SimpleConfirmationPage {...props} />
);

export default FriendConfirmationPage;
