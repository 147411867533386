import React from 'react';

import { replaceQueryParameters } from 'shared/replace-query-parameters';
import interpolate from 'shared/interpolate';
import useUrlState from 'hooks/use-url-state';

import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import RichText from 'components/rich-text/rich-text';

import { MemberTypeCard as Props } from './member-type-card.types';

const MemberTypeCard: React.FC<Props> = ({
  description,
  id,
  isRecommended,
  link,
  name,
}) => {
  // NOTE: Don't override the 'memberTypeId' parameter (since this is what the user is supposed to choose here)
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [{ memberTypeId, ...restParameters }] = useUrlState();

  return (
    <div className="member-type-card">
      {name && (
        <Text
          className="member-type-card__name"
          elementName="h2"
          size={Text.sizes.headline3}
          theme={Text.themes.normal}
        >
          {name}
        </Text>
      )}
      <Text>
        <RichText {...description} className="member-type-card__description" />
      </Text>

      <LinkButton
        data-test-member-type={id}
        aria-label={interpolate(link.template, { name })}
        className="member-type-card__link"
        variant={
          isRecommended
            ? LinkButton.variants.cta
            : LinkButton.variants.secondary
        }
        href={replaceQueryParameters(link.href, restParameters)}
        text={link.text}
      />
    </div>
  );
};

export default MemberTypeCard;
