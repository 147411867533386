import Check from '@sats-group/icons/16/check';
import React from 'react';

import { ThemedComponent } from 'client/types';

import { mod } from 'ts/add-bem-modifiers';

import Flag from 'sats-ui-lib/react/flag';
import Text from 'sats-ui-lib/react/text';

import Price from 'components/price/price';

import { MembershipCard as Props, themes } from './membership-card.types';

const MembershipCard: ThemedComponent<Props, typeof themes> = ({
  details,
  hideCheckmark,
  id,
  isMultiSelect,
  isSelected,
  price,
  tag,
  tagline,
  theme = themes.full,
  title,
}) => {
  const check = hideCheckmark ? undefined : (
    <div
      className={mod('membership-card__check', {
        checked: isSelected,
        multi: isMultiSelect,
      })}
    >
      {isSelected && <Check />}
    </div>
  );

  const content =
    theme === themes.simple ? (
      <div className="membership-card__button">
        <div>{check}</div>
        <Text theme={Text.themes.normal} size={Text.sizes.button}>
          {title}
          {price?.amount && !price.period ? ` (${price.amount})` : null}
          {price?.amount && price.period
            ? ` (${price.amount} ${price.period})`
            : null}
        </Text>
      </div>
    ) : (
      <React.Fragment>
        {check}

        <Text
          className="membership-card__title"
          elementName="h3"
          size={Text.sizes.headline3}
          theme={Text.themes.normal}
        >
          {title}
        </Text>

        {tag && (
          <div className="membership-card__tag">
            <Flag text={tag} />
          </div>
        )}

        {price && (
          <div className="membership-card__price">
            <Price
              color={Price.colors.orange}
              size={Price.sizes.medium}
              {...price}
            />
          </div>
        )}

        {tagline && (
          <Text className="membership-card__tagline" size={Text.sizes.small}>
            {tagline}
          </Text>
        )}

        {details && (
          <Text className="membership-card__details" size={Text.sizes.small}>
            {details}
          </Text>
        )}
      </React.Fragment>
    );

  return (
    <div
      aria-current={isSelected}
      className={mod('membership-card', theme, { 'has-tag': tag })}
      key={id}
    >
      {content}
    </div>
  );
};

MembershipCard.themes = themes;

export default MembershipCard;
