import React, { useState } from 'react';

import apiHelper from 'client/ts/api-helper';

import Button from 'sats-ui-lib/react/button';
import Modal from 'sats-ui-lib/react/modal';
import Text from 'sats-ui-lib/react/text';

import Spinner from 'components/spinner/spinner';

import { WebAdminApiButton as Props } from './web-admin-api-button.types';

const WebAdminApiButton: React.FunctionComponent<Props> = ({
  text,
  url,
  variant,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalContent, setModalContent] = useState<string | undefined>(
    undefined
  );

  const onClick = async () => {
    setIsLoading(true);
    apiHelper
      .post(url)
      .then(body => setModalContent(JSON.stringify(body, null, 2)))
      .catch(error => setModalContent(JSON.stringify(error, null, 2)))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="web-admin-api-button">
      {isLoading ? <Spinner theme={Spinner.themes.overlay} /> : null}
      <Button
        variant={variant ? variant : Button.variants.primary}
        disabled={isLoading}
        onClick={onClick}
        text={text}
      />
      {modalContent ? (
        <Modal
          closeLabel="Close modal"
          onClose={() => setModalContent(undefined)}
          ariaLabel="Modal"
          id="Modal"
        >
          <div>
            <Text elementName="h2">Result</Text>
            <code>
              <pre>{modalContent}</pre>
            </code>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default WebAdminApiButton;
