import React from 'react';

import type { DropInConfirmationPage as Props } from './drop-in-confirmation-page.types';

import SimpleConfirmationPage from '../simple-confirmation-page/simple-confirmation-page';

const DropInConfirmationPage: React.FC<Props> = props => (
  <SimpleConfirmationPage {...props} />
);

export default DropInConfirmationPage;
