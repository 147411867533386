import React from 'react';

import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import Link from 'components/link/link';
import Logo from 'components/logo/logo';

import { ErrorPage as Props } from './error-page.types';

const ErrorPage: React.FunctionComponent<Props> = ({
  links = [],
  text,
  title,
}) => {
  return (
    <main className="error-page">
      <ContentContainer
        className="error-page__content"
        theme={ContentContainer.themes.narrow}
      >
        <div className="error-page__logo">
          <Logo />
        </div>
        <Text
          className="error-page__title"
          elementName="h1"
          size={Text.sizes.headline1}
          theme={Text.themes.headline}
          italic
        >
          {title}
        </Text>
        {text && (
          <Text className="error-page__text" size={Text.sizes.large}>
            {text}
          </Text>
        )}
        <ul className="error-page__links">
          {links.map(link => (
            <li key={link.href}>
              <Link {...link} />
            </li>
          ))}
        </ul>
      </ContentContainer>
    </main>
  );
};

export default ErrorPage;
