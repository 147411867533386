import { useEffect } from 'react';

import { tuple } from 'shared/tuple';

const dispatch =
  <T>(eventName: string) =>
  (data: T) => {
    window.dispatchEvent(new CustomEvent(eventName, { detail: data }));
  };

const subscriber =
  <T>(listener: (data: T) => void) =>
  (event: CustomEvent<T>) =>
    listener(event.detail);

const subscribe = <T>(eventName: string, listener: (data: T) => void) => {
  window.addEventListener(eventName, subscriber(listener));
  return () => window.removeEventListener(eventName, subscriber(listener));
};

export default function useCustomEvent<T = any>(
  eventName: string,
  eventHandler: (data: T) => void
) {
  useEffect(() => subscribe<T>(eventName, eventHandler), []);

  return tuple([dispatch<T>(eventName)]);
}
