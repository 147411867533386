import React, { HTMLAttributes } from 'react';

import { ObjectValues } from 'client/types';

export const themes = {
  medium: 'medium',
  narrow: 'narrow',
  veryNarrow: 'very-narrow',
  wide: 'wide',
} as const;

export interface ContentContainer extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  theme?: ObjectValues<typeof themes>;
}
