import React from 'react';

import Modal from 'sats-ui-lib/react/modal/modal';
import Text from 'sats-ui-lib/react/text';

import RichText from 'components/rich-text/rich-text';

import { CheckoutSummaryModal as Props } from './checkout-summary-modal.types';

const CheckoutSummaryModal: React.FunctionComponent<Props> = ({
  hide = () => {},
  modal,
  subTitle,
  text,
  title,
}) => (
  <Modal onClose={hide} {...modal}>
    <div className="checkout-summary-modal">
      <Text
        className="checkout-summary-modal__title"
        elementName="h2"
        size={Text.sizes.headline2}
        theme={Text.themes.headline}
      >
        {title}
      </Text>
      {subTitle && (
        <Text
          className="checkout-summary-modal__subtitle"
          size={Text.sizes.small}
        >
          {subTitle}
        </Text>
      )}
      {text && (
        <RichText
          className="checkout-summary-modal__text"
          theme={RichText.themes.whiteCheckmarkList}
          {...text}
        />
      )}
    </div>
  </Modal>
);

export default CheckoutSummaryModal;
