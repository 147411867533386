import adyenStyles from '@adyen/adyen-web/dist/adyen.css';
import cn from 'classnames';
import type { PaymentAction } from '@adyen/adyen-web/dist/types/types';
import type DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import React, { useEffect, useRef, useState } from 'react';
import { AsyncReturnType } from 'type-fest';

import { publish } from 'ts/messages';
import { types } from 'ts/messages.types';
import { replaceQueryParameters } from 'shared/replace-query-parameters';
import absoluteUrl from 'ts/absolute-url';
import apiHelper from 'ts/api-helper';
import extractServerError from 'ts/extract-server-error';
import handleAdyenResult from 'shared/handle-adyen-result';
import useUrlState from 'hooks/use-url-state';

import Spinner from 'components/spinner/spinner';
import TabTrapper from 'components/tab-trapper/tab-trapper';

import { useCheckoutSessionContext } from 'contexts/checkout-session-context-provider';

import { Payment as Props } from './payment.types';

const magicAdyenId = 'dropin';
const magic3dsId = 'payment-3ds-frame';

const handleError = (error: Error) => {
  const message = extractServerError(error);
  if (message) {
    publish({ text: message, theme: types.error });
  }
};

type ApiResponse = {
  action?: PaymentAction;
  resultCode: string;
  pspReference?: string;
};

type AdyenState = {
  data: {
    billingAddress: unknown;
    browserInfo: unknown;
    paymentMethod: Record<string, unknown>;
  };
  [key: string]: any;
};

const Payment: React.FunctionComponent<Props> = ({
  adyenClientKey,
  adyenEnv,
  locale,
  memberId: memberIdProp,
  onPaymentComplete = () => {},
  onPaymentError = () => {},
  payEndpoint,
  paymentDetailsEndpoint,
  paymentMethodsEndpoint,
  paymentMethodsErrorMessage,
  paymentErrorMessage,
  paymentRefusedMessage,
  returnUrl,
  sessionId: sessionIdProp,
}) => {
  const [is3ds2, setIs3ds2] = useState(false);
  const { checkIsExpired } = useCheckoutSessionContext();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState();
  const [query, setUrlState] = useUrlState();
  const [AdyenCheckout, setAdyenCheckout] =
    useState<typeof import('@adyen/adyen-web')['default']>();
  const checkoutRef =
    useRef<AsyncReturnType<NonNullable<typeof AdyenCheckout>>>();
  const [adyenCss, setAdyenCss] = useState<string>();

  const [{ memberId, sessionId }, setIds] = useState({
    memberId: memberIdProp,
    sessionId: sessionIdProp,
  });

  const show3ds2 = () => {
    setIs3ds2(true);
    // NOTE: Disable page scrolling:
    document.body.style.position = 'fixed';
  };

  const hide3ds2 = () => {
    document.body.style.position = '';
    setIs3ds2(false);
  };

  // NOTE: Both payment submit and payment details submit share the same response logic on purpose. See https://docs.adyen.com/checkout/drop-in-web
  const handleResponse = (response: ApiResponse, dropin: DropinElement) => {
    if (response.action) {
      switch (response.action.type) {
        case 'threeDS2':
        case 'threeDS2Challenge': // NOTE: To be deprecated.
        case 'threeDS2Fingerprint': {
          // NOTE: These types are handled separately from the default,
          // in order to be able to size the 3DS window
          if (!checkoutRef.current) {
            // NOTE: This should never happen
            break;
          }
          show3ds2();
          checkoutRef.current
            .createFromAction(response.action, {
              challengeWindowSize: '05', // NOTE: '05' is 100% * 100%
            })
            .mount(`#${magic3dsId}`);
          break;
        }
        default: {
          dropin.handleAction(response.action);
          break;
        }
      }
      return;
    }

    const result = handleAdyenResult(response.resultCode, {
      error: paymentErrorMessage,
      refused: paymentRefusedMessage,
    });

    if (result.success) {
      setUrlState({ stale: 'true' });
      onPaymentComplete(response.pspReference);
    } else {
      hide3ds2();
      onPaymentError();
      publish({ text: result.error, theme: types.error });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    import(/* webpackChunkName: "adyen" */ '@adyen/adyen-web')
      .then(module => setAdyenCheckout(() => module.default))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setAdyenCss(adyenStyles);
  }, []);

  useEffect(() => {
    // NOTE: Props always "win" over query
    if (memberIdProp && sessionIdProp) {
      setIds({
        memberId: memberIdProp,
        sessionId: sessionIdProp,
      });
    } else if (query.memberId && query.sessionId) {
      setIds({
        memberId: String(query.memberId),
        sessionId: String(query.sessionId),
      });
    }
  }, [query, memberIdProp, sessionIdProp]);

  useEffect(() => {
    if (!memberId || !sessionId) {
      return;
    }

    setIsLoading(true);

    apiHelper
      .get(
        replaceQueryParameters(paymentMethodsEndpoint, {
          memberId,
          sessionId,
        })
      )
      .then(setPaymentMethods)
      .catch(checkIsExpired)
      .catch(() =>
        publish({ text: paymentMethodsErrorMessage, theme: types.error })
      )
      .finally(() => setIsLoading(false));
  }, [memberId, sessionId]);

  useEffect(() => {
    if (!AdyenCheckout || !paymentMethods) {
      return;
    }

    AdyenCheckout({
      clientKey: adyenClientKey,
      environment: adyenEnv,
      locale,
      onAdditionalDetails: (state: AdyenState, dropin: DropinElement) => {
        setIsLoading(true);
        apiHelper
          .post(paymentDetailsEndpoint, {
            memberId,
            sessionId,
            paymentDetails: state.data,
          })
          .then(response => handleResponse(response, dropin))
          .catch(checkIsExpired)
          .catch(handleError)
          .finally(() => setIsLoading(false));
      },
      onSubmit: (state: AdyenState, dropin: DropinElement) => {
        setIsLoading(true);
        apiHelper
          .post(payEndpoint, {
            memberId,
            sessionId,
            originUrl: `${window.location.protocol}//${window.location.host}`,
            browserInfo: state.data.browserInfo,
            billingAddress: state.data.billingAddress,
            // NOTE: URLs provided by our web server are relative. Adyen needs an absolute URL.
            redirectUrl: absoluteUrl(replaceQueryParameters(returnUrl, query)),
            ...state.data.paymentMethod,
          })
          .then(response => handleResponse(response, dropin))
          .catch(checkIsExpired)
          .catch(handleError)
          .finally(() => setIsLoading(false));
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
        },
      },
      paymentMethodsResponse: paymentMethods,
    }).then(checkout => {
      checkout.create('dropin').mount(`#${magicAdyenId}`);
      checkoutRef.current = checkout;
    });
  }, [AdyenCheckout, paymentMethods, sessionId, memberId]);

  return (
    <div className="payment" data-adyen-client-key={adyenClientKey}>
      {isLoading && <Spinner theme={Spinner.themes.overlay} />}
      {adyenCss ? <link rel="stylesheet" href={adyenCss} /> : null}
      {/* NOTE: We use `tabIndex` here so the tests can focus this element. */}
      <div data-adyen-mount-point id={magicAdyenId} tabIndex={-1} />

      <TabTrapper isActive={is3ds2}>
        <div
          aria-modal="true"
          role="dialog"
          className={cn('payment__3ds2-wrapper', {
            'payment__3ds2-wrapper--active': is3ds2,
          })}
        >
          {isLoading && <Spinner theme={Spinner.themes.overlay} />}
          <div
            className="payment__3ds2-frame"
            data-adyen-3ds2-frame
            id={magic3dsId}
          />
        </div>
      </TabTrapper>
    </div>
  );
};

export default Payment;
