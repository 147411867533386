import Close from '@sats-group/icons/24/close';
import Crosshairs from '@sats-group/icons/24/geoposition';
import Location from '@sats-group/icons/24/location';
import React, { useEffect, useState } from 'react';
import Search from '@sats-group/icons/24/search';

import { publish } from 'ts/messages';
import { types } from 'ts/messages.types';
import { replaceQueryParameters } from 'shared/replace-query-parameters';
import apiHelper from 'ts/api-helper';
import debounce from 'ts/debounce';
import useUrlState from 'hooks/use-url-state';

import Button from 'sats-ui-lib/react/button';
import Text from 'sats-ui-lib/react/text';

import ClubCard from 'components/club-card/club-card';
import { ClubCard as ClubCardProps } from 'components/club-card/club-card.types';
import List from 'components/list/list';
import Spinner from 'components/spinner/spinner';

import { ClubSearch as Props } from './club-search.types';

enum Mode {
  all = 'all',
  geoLocation = 'geo-location',
  region = 'region',
  search = 'search',
}

const ClubSearch: React.FunctionComponent<Props> = ({
  allClubsButtonLabel,
  allClubsStateLabel,
  endpoint,
  geoLocationButtonText,
  genericError,
  noResultsText,
  customQuery,
  regions,
  regionsTitle,
  searchButtonText,
  searchPlaceholder,
}) => {
  const [query, setQuery] = useUrlState();

  const [clubs, setClubs] = useState<ClubCardProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [didSearch, setDidSearch] = useState(false);

  // NOTE: These names are extra verbose since they'll be stuck in the URL until the end of the checkout and there should be no confusion as to what view they belong to
  const { clubSearchMode, clubSearchRegion } = query;
  const mode = Object.values(Mode).includes(clubSearchMode as Mode)
    ? (clubSearchMode as Mode)
    : Mode.search;
  const clubSearchTerm = String(query.clubSearchTerm || '');

  const setMode = (clubSearchMode: Mode) => setQuery({ clubSearchMode });
  const setSearchTerm = (clubSearchTerm: string) =>
    setQuery({ clubSearchTerm });
  const setRegion = (clubSearchRegion: string) =>
    setQuery({ clubSearchRegion });

  const enableRegion = (region: string) => {
    setMode(Mode.region);
    setRegion(region);
  };

  const search = (searchParameters: Record<string, any>) => {
    setIsLoading(true);
    setDidSearch(true);
    return apiHelper
      .get(
        replaceQueryParameters(endpoint, {
          ...query,
          ...searchParameters,
          ...customQuery,
        })
      )
      .then(setClubs)
      .catch(() => {
        publish({ text: genericError, theme: types.error });
      })
      .finally(() => setIsLoading(false));
  };

  const reset = () => {
    setSearchTerm('');
    setRegion('');
    setClubs([]);
    setMode(Mode.search);
  };

  useEffect(() => {
    switch (mode) {
      case Mode.all: {
        search({ showAllClubs: true });
        break;
      }
      case Mode.search: {
        if (clubSearchTerm.length > 0) {
          search({ clubSearchTerm });
        } else {
          setClubs([]);
        }

        break;
      }
      case Mode.region: {
        search({ clubSearchRegion });
        break;
      }
      case Mode.geoLocation: {
        setClubs([]);
        setIsLoading(true); // NOTE: Finding the user's position sometimes takes a while
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords;
            search({ latitude, longitude });
          },
          () => {
            reset();
            setIsLoading(false);
          }
        );
        break;
      }
    }
  }, [mode, clubSearchTerm, clubSearchRegion]);

  const shouldShowOptions =
    mode === Mode.search && !clubSearchTerm && !isLoading;

  return (
    <div className="club-search">
      <div className="club-search__search">
        <div className="club-search__content">
          <div className="club-search__search-icon">
            <Search />
          </div>

          {mode === Mode.search && (
            <form
              className="club-search__form"
              action="" // NOTE: The form needs an `action` in order for a `Search` button to show in the iOS keyboard
              onSubmit={e => {
                e.preventDefault();
                search({ clubSearchTerm });
              }}
            >
              <input
                className="club-search__input"
                defaultValue={clubSearchTerm}
                onInput={debounce(
                  e => setSearchTerm((e.target as HTMLInputElement).value),
                  300
                )}
                placeholder={searchPlaceholder}
                type="search"
              />
              <Button
                className="club-search__submit"
                variant={Button.variants.cta}
                onClick={() => search({ clubSearchTerm })}
                type="submit"
                text={searchButtonText}
              />
            </form>
          )}

          {mode !== Mode.search && (
            <React.Fragment>
              <div className="club-search__state-label">
                {mode === Mode.all ? allClubsStateLabel : null}
                {mode === Mode.geoLocation ? geoLocationButtonText : null}
                {mode === Mode.region
                  ? regions.find(({ id }) => id === clubSearchRegion)?.text
                  : null}
              </div>
              <button
                className="club-search__cancel"
                onClick={reset}
                type="button"
              >
                <Close />
              </button>
            </React.Fragment>
          )}
        </div>

        {shouldShowOptions && (
          <div className="club-search__options">
            <button
              className="club-search__option-button"
              onClick={() => setMode(Mode.geoLocation)}
            >
              <Crosshairs />
              {geoLocationButtonText}
            </button>

            {regions.length > 0 && (
              <div className="club-search__option-group">
                <Text
                  className="club-search__option-group-title"
                  id="club-search-regions-label"
                  size={Text.sizes.small}
                >
                  {regionsTitle}
                </Text>

                <List
                  aria-labelledby="club-search-regions-label"
                  theme={List.themes.noMargin}
                >
                  {regions.map(({ id, text }) => (
                    <button
                      className="club-search__option-button"
                      onClick={() => enableRegion(id)}
                      key={text}
                    >
                      <Location />
                      {text}
                    </button>
                  ))}
                </List>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="club-search__results">
        {isLoading && <Spinner theme={Spinner.themes.centered} />}

        {clubs.length > 0 && (
          <List theme={List.themes.marginMedium}>
            {clubs.map(club => (
              <ClubCard key={club.title} {...club} />
            ))}
          </List>
        )}

        {!isLoading && didSearch && clubs.length === 0 && (
          <div className="club-search__no-results">{noResultsText}</div>
        )}

        {!isLoading && mode !== Mode.all ? (
          <div className="club-search__show-all">
            <Button
              variant={Button.variants.secondary}
              data-test-show-all-clubs
              onClick={() => setMode(Mode.all)}
              text={allClubsButtonLabel}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ClubSearch;
